import { API_URL } from '../utils';

export interface IApiResponse<T> {
  code: number;
  message: string;
  time: string;
  data: T;
}

export interface IApiResponseReturn<T> {
  code: number | null;
  message: string | null;
  time: string | null;
  data: T | null;
  isLoading: boolean;
  isError: boolean;
}

export interface ICatalogCard {
  id: number;
  price: number;
  image: string;
  name: string;
  text: string;
}

export interface ICountry {
  id: number;
  name: string;
}

export interface IRegion {
  id: number;
  name: string;
}

export interface ICity {
  id: number;
  name: string;
}

export interface IPoints {
  points: number;
}

export interface IAddress {
  country: string | null;
  region: string | null;
  city: string | null;
  zip: number;
  street: string | null;
  house: string | null;
  apartment: string | null;
}

export interface IContactInfo {
  phone: string | null;
  address: IAddress;
}

export interface IStoreInfo {
  country: string | null;
  region: string | null;
  city: string | null;
  address: string | null;
  name: string | null;
}

export interface IGetUserInfo {
  id: number;
  name: string | null;
  surname: string | null;
  patronymic: string | null;
  email: string | null;
  avatar: string | null;
  gender: string | null;
  birthDate: string | null;
  contactInfo: IContactInfo;
  storeInfo: IStoreInfo;
}

export interface ISnilsInn {
  snils: string | null;
  inn: string | null;
}

export interface IBankCard {
  name: string | null;
  surname: string | null;
  patronymic: string | null;
  accountNumber: string | null;
  bankOpen: string | null;
  correspondent: string | null;
  bankBic: string | null;
}

export interface IPassport {
  name: string | null;
  surname: string | null;
  patronymic: string | null;
  birthPlace: string | null;
  gender: string | null;
  birthDate: string | null;
  passportNumber: string | null;
  issuedBy: string | null;
  departamentCode: string | null;
  issueDate: string | null;
  registrationAddress: string | null;
  imagesPassport: [string | null];
}

export interface ITransaction {
  id: number;
  type: ETransactionType;
  date: string;
  amount: number;
}

export enum ETransactionType {
  SCAN = 'scan',
  CERTIFICATE = 'certificate',
  CASHPRIZE = 'cashprize',
}

export interface IHttpError extends Error {
  status: number;
}

export const EApiUrls = {
  CATALOG: `${API_URL}/catalog/list/`,
  CITIES: `${API_URL}/get/cities/?region=`,
  COUNTRIES: `${API_URL}/get/countries/`,
  REGIONS: `${API_URL}/get/regions/?country=`,
  POINTS: `${API_URL}/user/get_point/`,
  GET_USER_INFO: `${API_URL}/user/get_user_info/`,
  GET_SNILS_INN: `${API_URL}/user/get_snils_inn/`,
  BANK_CARD: `${API_URL}/user/get_bank_card/`,
  PASSPORT_DATA: `${API_URL}/user/get_passport_data/`,
  GET_TRANSACTIONS: `${API_URL}/user/get_transactions/`,
  UPDATE_SNILS: `${API_URL}/user/update_snils_inn/`,
  UPDATE_BANK_CARD: `${API_URL}/user/update_bank_card/`,
  UPDATE_PASSPORT: `${API_URL}/user/update_passport_data/`,
  ORDER: `${API_URL}/catalog/element/order/`,
  LOGOUT: `${API_URL}/auth/logout/`,
  LOGIN_EMAIL: `${API_URL}/auth/login_by_email/`,
  LOGIN_PHONE: `${API_URL}/auth/login_by_phone/`,
  CHECK_LOGIN_PHONE_CODE: `${API_URL}/auth/check_login_phone_code/`,
  REGISTER: `${API_URL}/auth/register/`,
  RESET_CODE_EMAIL: `${API_URL}/auth/send_recovery_code_to_email/`,
  ACTIVATE_PROMOCODE: `${API_URL}/promocode/activate/`,
  CHANGE_PASSWORD: `${API_URL}/auth/change_password/`,
  RECOVERY_CODE_EMAIL: `${API_URL}/auth/check_recovery_code_in_email/`,
} as const;

export const OPTIONS = {
  revalidateOnFocus: false,
  refreshInterval: 500000,
  dedupingInterval: 500000,
};
