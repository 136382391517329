import React from 'react';
import { IIcon } from '..';

const WhatsAppIcon: React.FC<IIcon> = ({ color, className }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.39942 17.1019C2.15646 15.0066 1.72117 12.5296 2.17528 10.136C2.62938 7.74249 3.94165 5.59705 5.86566 4.10257C7.78966 2.60809 10.1931 1.86738 12.6246 2.01953C15.056 2.17169 17.3484 3.20624 19.071 4.92892C20.7937 6.6516 21.8283 8.94391 21.9805 11.3754C22.1326 13.8069 21.3919 16.2103 19.8975 18.1343C18.403 20.0583 16.2576 21.3706 13.864 21.8247C11.4705 22.2788 8.99347 21.8436 6.89814 20.6006L6.89817 20.6005L3.44292 21.5877C3.29996 21.6286 3.14868 21.6304 3.00476 21.5931C2.86084 21.5559 2.72951 21.4808 2.62438 21.3756C2.51925 21.2705 2.44415 21.1392 2.40685 20.9952C2.36956 20.8513 2.37143 20.7 2.41228 20.5571L3.39949 17.1018L3.39942 17.1019Z"
        stroke="#7BB91A"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.5166 17.8316C13.4198 17.8332 12.3335 17.6183 11.3199 17.1993C10.3063 16.7803 9.38532 16.1654 8.60977 15.3898C7.83421 14.6143 7.21931 13.6933 6.80031 12.6797C6.3813 11.6661 6.16642 10.5797 6.16798 9.48294C6.17046 8.71092 6.47905 7.97141 7.02606 7.42662C7.57307 6.88183 8.31383 6.57625 9.08585 6.57691C9.21248 6.57691 9.33686 6.61038 9.44638 6.67394C9.5559 6.7375 9.64668 6.82888 9.7095 6.93882L10.9278 9.07089C11.0013 9.19947 11.0392 9.3453 11.0376 9.49338C11.036 9.64146 10.9951 9.78645 10.9189 9.91344L9.94043 11.5442C10.4438 12.6613 11.3383 13.5558 12.4554 14.0591L14.0861 13.0807C14.2131 13.0045 14.3581 12.9635 14.5062 12.962C14.6543 12.9604 14.8001 12.9983 14.9287 13.0718L17.0607 14.2901C17.1707 14.3529 17.2621 14.4437 17.3256 14.5532C17.3892 14.6627 17.4227 14.7871 17.4227 14.9137C17.4205 15.6849 17.114 16.424 16.5698 16.9704C16.0256 17.5167 15.2878 17.8263 14.5166 17.8316Z"
        stroke="#7BB91A"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default WhatsAppIcon;
