import React from 'react';
import { ContactLink, MailIcon, TelegramIcon, TelephoneIcon, ViberIcon, WhatsAppIcon } from '../../components';
import { PageLayout } from '../../layout';

const ContactsPage: React.FC = () => {
  return (
    <PageLayout isBtnBack={true}>
      <div className="w-full bg-white pt-[20px] px-[16px] pb-[88px] rounded-lg flex-1 justify-center self-start">
        <h2 className="font-medium mb-[20px] text-[24px] text-header text-start">Помощь и контакты</h2>
        <p className="text-main font-normal text-sm mb-[32px]">
          Телефон справочной службы работает с 4 до 17 часов по московскому времени (по России бесплатно)
        </p>
        <div className="mb-[40px] w-full">
          <ul className="list-none p-0">
            <li className="my-[12px]">
              <ContactLink
                link="tel:88002506888"
                icon={<TelephoneIcon />}
                name="Телефон справочной службы"
                description="8 800 250-68-88"
              />
            </li>
            <li className="my-[12px]">
              <ContactLink
                link="mailto:marketing@grossaqua.ru"
                icon={<MailIcon />}
                name="Электронная почта"
                description="marketing@grossaqua.ru"
              />
            </li>
            <li className="my-[12px]">
              <ContactLink
                link="https://wa.me/"
                icon={<WhatsAppIcon />}
                name="WhatsApp"
                description="Перейти в приложение"
              />
            </li>
            <li className="my-[12px]">
              <ContactLink
                link="https://t.me/"
                icon={<TelegramIcon />}
                name="Telegram"
                description="Перейти в приложение"
              />
            </li>
            <li className="my-[12px]">
              <ContactLink link="#" icon={<ViberIcon />} name="Viber" description="Перейти в приложение" />
            </li>
          </ul>
        </div>
      </div>
    </PageLayout>
  );
};

export default ContactsPage;
