import React from 'react';
import { Link } from 'react-router-dom';
import { URLS } from '../../utils';

const NoAccount: React.FC = () => {
  return (
    <div className="flex justify-center items-center mt-3 flex-wrap gap-x-[8px]">
      <p className="text-main text-base font-normal">Нет аккаунта?</p>
      <Link to={URLS.REGISTER} className="text-green">
        Зарегистрироваться
      </Link>
    </div>
  );
};

export default NoAccount;
