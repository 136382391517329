import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowRightIcon } from '..';

interface IAccountLink {
  label: string;
  url: string;
  icon: React.ReactElement;
}

const AccountLink: React.FC<IAccountLink> = ({ label, url, icon }) => {
  return (
    <Link className="flex p-[12px] rounded-xl bg-light_grey items-center justify-between" to={url}>
      <div className="flex items-center">
        <div className="p-2 rounded-lg bg-white">
          {React.cloneElement(icon, { className: 'h-[14px] w-[14px]', color: '#4B4B4B' })}
        </div>
        <p className="py-[9px] pl-[12px]">{label}</p>
      </div>
      <div className="rounded-lg items-end">
        <ArrowRightIcon className="h-[14px] w-[14px]" />
      </div>
    </Link>
  );
};

export default AccountLink;
