import React from 'react';
import { IIcon } from '..';

const HelpContactIcon: React.FC<IIcon> = ({ color, className }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 17.4545V17.4636M8.36364 10.1818C8.36364 8.17351 9.99169 6.54545 12 6.54545C14.0083 6.54545 15.6364 8.17351 15.6364 10.1818C15.6364 11.8795 14.4729 13.3055 12.8999 13.706C12.4133 13.8298 12 14.2252 12 14.7273M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
        stroke="#333333"
        strokeWidth="1.3"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default HelpContactIcon;
