import React from 'react';
import { BtnBack } from '../components';

interface IPageLayout {
  isBtnBack: boolean;
  children: React.ReactNode;
}

const PageLayout: React.FC<IPageLayout> = ({ isBtnBack, children }) => {
  return (
    <div className="h-full flex flex-col justify-start items-center bg-white w-full">
      <div className="w-full bg-white pt-[64px] flex flex-1 flex-col justify-start flex-grow">
        {isBtnBack && <BtnBack />}
        {children}
      </div>
    </div>
  );
};

export default PageLayout;
