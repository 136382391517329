import React, { useState } from 'react';
import CryptoJS from 'crypto-js';
import { useNavigate } from 'react-router-dom';
import {
  BtnBack,
  Button,
  EButtonClass,
  EButtonType,
  EInputType,
  InputField,
  InputPassword,
  SuccesSmallNotification,
} from '../../components';
import { EApiUrls } from '../../../api';
import { CustomErrorsMsgs, setCookie, URLS } from '../../../utils';
import { HASHING, SESSION_ID_COOKIE } from '../../../utils/consts';

const FAIL_FETCH_CODE: string = 'Ошибка при отправке кода восстановления. Проверьте введенный email';
const FAIL_FETCH_NOSUCCESS_CODE: string = 'Неверный код восстановления';
const FAIL_FETCH_CHANGE_PASSWORD: string = 'Ошибка при изменении пароля';
const SUCCESS_CHANGE_PASSWORD: string = 'Пароль успешно изменен';
const SUCCESS_SEND_CODE: string = 'Код для восстановления пароля отправлен на указанную почту';

export const ResetPasswordPage: React.FC = () => {
  const [step, setStep] = useState('email');
  const [email, setEmail] = useState('');
  const [code, setCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [codeSMSEmail, setCodeSMSEmail] = useState<number | null>(null);
  const [userID, setUserId] = useState<string | null>(null);
  const [isSuccessSendCodeSmsEmail, setIsSuccessSendCodeSmsEmail] = useState<boolean>(false);
  const [isSuccessChangePassword, setIsSuccessChangePassword] = useState<boolean>(false);

  const navigate = useNavigate();

  const handleEmailSubmit = async () => {
    setErrorMessage(null);
    try {
      const response = await fetch(EApiUrls.RESET_CODE_EMAIL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      const { data } = await response.json();

      if (response.ok) {
        setCodeSMSEmail(data.codeSMSEmail);
        setUserId(data.user_id);
        setIsSuccessSendCodeSmsEmail(true);
        setTimeout(() => {
          setStep('code');
          setIsSuccessSendCodeSmsEmail(false);
        }, 3000);
      } else {
        setErrorMessage(data.message || FAIL_FETCH_CODE);
      }
    } catch (error) {
      setErrorMessage(FAIL_FETCH_CODE);
    }
  };

  const handleCodeSubmit = async () => {
    setErrorMessage(null);
    try {
      const response = await fetch(EApiUrls.RECOVERY_CODE_EMAIL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          code,
          codeSMSEmail,
        }),
      });

      const data = await response.json();

      if (response.ok) {
        setStep('resetPassword');

        if (data?.data?.phpsessid) {
          setCookie(SESSION_ID_COOKIE, data.data.phpsessid);
        }
      } else {
        setErrorMessage(data.message || FAIL_FETCH_NOSUCCESS_CODE);
      }
    } catch (error) {
      setErrorMessage(FAIL_FETCH_NOSUCCESS_CODE);
    }
  };

  const handlePasswordResetSubmit = async () => {
    setErrorMessage(null);
    if (newPassword !== confirmPassword) {
      setErrorMessage(CustomErrorsMsgs.PASSWORD_NO_MATCH);
      return;
    }

    const useHashing = HASHING === 'true';
    const finalPassword = useHashing ? CryptoJS.MD5(newPassword).toString() : newPassword;
    const finalConfirmPassword = useHashing ? CryptoJS.MD5(confirmPassword).toString() : confirmPassword;

    try {
      const response = await fetch(EApiUrls.CHANGE_PASSWORD, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          password: finalPassword,
          confirm_password: finalConfirmPassword,
          user_id: userID,
        }),
      });

      const { data } = await response.json();

      if (response.ok) {
        if (data?.phpsessid) {
          setCookie(SESSION_ID_COOKIE, data.phpsessid);
        }

        setIsSuccessChangePassword(true);
        setTimeout(() => navigate(URLS.LOGIN), 2000);
      } else {
        setErrorMessage(data.message || FAIL_FETCH_CHANGE_PASSWORD);
      }
    } catch (error) {
      setErrorMessage(FAIL_FETCH_CHANGE_PASSWORD);
    }
  };

  return (
    <div className="h-full flex flex-col justify-start items-center bg-white w-full mx-auto pt-[64px]">
      <BtnBack />

      {step === 'email' && (
        <div className="relative flex-1 w-full bg-white pr-[16px] pl-[16px] pt-[16px] pb-[48px] rounded-lg flex flex-col justify-between">
          <div>
            <h2 className="text-2xl font-medium mb-8 text-[26px] text-header text-start">Восстановление пароля</h2>
            <p className="text-sm my-4 font-normal">
              Введите адрес электронной почты, и мы вышлем вам инструкции по сбросу пароля
            </p>

            <InputField
              label="Email"
              type={EInputType.EMAIL}
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Введите email"
            />

            {errorMessage && <div className="text-[red] mt-4">{errorMessage}</div>}
          </div>
          <div className="w-full">
            <Button
              text="Выслать код"
              nameClass={EButtonClass.DEF}
              isLink={false}
              typeBtn={EButtonType.BUTTON}
              handle={handleEmailSubmit}
            />
          </div>
        </div>
      )}

      {step === 'code' && (
        <div className="relative flex-1 w-full bg-white pr-[16px] pl-[16px] pt-[16px] pb-[48px] rounded-lg flex flex-col justify-between">
          <div>
            <h2 className="text-2xl font-medium mb-8 text-[26px] text-header text-start">Восстановление пароля</h2>
            <p className="text-sm my-4 font-normal">Введите код, который пришёл вам на электронную почту</p>

            <InputField
              label="Код восстановления"
              name="code"
              type={EInputType.TEXT}
              value={code}
              onChange={(e) => setCode(e.target.value)}
              placeholder="Введите код"
            />

            {errorMessage && <div className="text-[red] mt-4">{errorMessage}</div>}
          </div>
          <div className="w-full">
            <Button
              text="Проверить код"
              nameClass={EButtonClass.DEF}
              isLink={false}
              typeBtn={EButtonType.BUTTON}
              handle={handleCodeSubmit}
            />
          </div>
        </div>
      )}

      {step === 'resetPassword' && (
        <div className="relative flex-1 w-full bg-white pr-[16px] pl-[16px] pt-[16px] pb-[48px] rounded-lg flex flex-col justify-between">
          <div>
            <h2 className="text-2xl font-medium mb-8 text-[26px] text-header text-start">Восстановление пароля</h2>
            <p className="text-sm my-4 font-normal">Придумайте пароль</p>

            <InputPassword
              label="Новый пароль"
              placeholder="Введите новый пароль"
              value={newPassword}
              name="password"
              onChange={(e) => setNewPassword(e.target.value)}
            />

            <InputPassword
              label="Подтвердите новый пароль"
              placeholder="Подтвердите новый пароль"
              value={confirmPassword}
              name="confirm_password"
              onChange={(e) => setConfirmPassword(e.target.value)}
            />

            {errorMessage && <div className="text-[red] mt-4">{errorMessage}</div>}
          </div>
          <div className="w-full">
            <Button
              text="Восстановить пароль"
              nameClass={EButtonClass.DEF}
              isLink={false}
              typeBtn={EButtonType.BUTTON}
              handle={handlePasswordResetSubmit}
            />
          </div>
        </div>
      )}

      {isSuccessSendCodeSmsEmail && <SuccesSmallNotification text={SUCCESS_SEND_CODE} />}
      {isSuccessChangePassword && <SuccesSmallNotification text={SUCCESS_CHANGE_PASSWORD} />}
    </div>
  );
};

export default ResetPasswordPage;
