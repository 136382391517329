import React, { useEffect, useState } from 'react';
import { Button, EButtonClass, EButtonType, InputField, SuccesSmallNotification } from '../../components';
import { PageLayout } from '../../layout';
import { EApiUrls, IBankCard, useGetBankCard } from '../../../api';
import { mutate } from 'swr';
import { URLS } from '../../../utils';
import { useNavigate } from 'react-router-dom';

const FAIL_FETCH: string =
  'Ошибка при обновлении данных банковских данных. Проверьте правильность данных и попробуйте снова.';

const CardDetailsPage: React.FC = () => {
  const [isSuccessUpdate, setIsSuccessUpdate] = useState<boolean>(false);
  const { data } = useGetBankCard();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const navigate = useNavigate();

  const [formData, setFormData] = useState<IBankCard>({
    name: null,
    surname: null,
    patronymic: null,
    accountNumber: null,
    bankOpen: null,
    correspondent: null,
    bankBic: null,
  });

  useEffect(() => {
    if (data) {
      setFormData({
        name: data.name,
        surname: data.surname,
        patronymic: data.patronymic,
        accountNumber: data.accountNumber,
        bankOpen: data.bankOpen,
        correspondent: data.correspondent,
        bankBic: data.bankBic,
      });
    }
  }, [data]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value || null,
    }));
  };

  const handleSubmit = async () => {
    setErrorMessage(null);
    try {
      const response = await fetch(EApiUrls.UPDATE_BANK_CARD, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: formData.name,
          surname: formData.surname,
          patronymic: formData.patronymic,
          accountNumber: formData.accountNumber,
          bankOpen: formData.bankOpen,
          correspondent: formData.correspondent,
          bankBic: formData.bankBic,
        }),
      });

      const { data } = await response.json();

      if (response.ok) {
        mutate(EApiUrls.BANK_CARD);
        setIsSuccessUpdate(true);
        setTimeout(() => navigate(URLS.ACCOUNT), 2000);
      } else {
        setErrorMessage(data.message || FAIL_FETCH);
      }
    } catch (error) {
      setErrorMessage(FAIL_FETCH);
    }
  };

  return (
    <PageLayout isBtnBack={true}>
      <div className="w-full bg-white pt-[16px] pb-[88px] px-[16px] rounded-lg justify-center self-start">
        <h2 className="font-medium mb-[32px] text-[24px] leading-[28.8px] text-header text-start">
          Реквизиты банковской карты
        </h2>
        <div className="mb-10">
          <InputField
            label="Имя"
            name="name"
            value={formData.name || ''}
            onChange={handleInputChange}
            placeholder="Введите имя"
          />

          <InputField
            label="Фамилия"
            name="surname"
            value={formData.surname || ''}
            onChange={handleInputChange}
            placeholder="Введите фамилию"
          />

          <InputField
            label="Отчество"
            name="patronymic"
            value={formData.patronymic || ''}
            onChange={handleInputChange}
            placeholder="Введите отчество"
          />

          <InputField
            label="Номер счета"
            name="accountNumber"
            value={formData.accountNumber || ''}
            onChange={handleInputChange}
            placeholder="Введите номер счета"
          />

          <InputField
            label="Банк, в котором открыт счет"
            name="bankOpen"
            value={formData.bankOpen || ''}
            onChange={handleInputChange}
            placeholder="Введите банк, в котором открыт счет"
          />

          <InputField
            label="Корреспондентский счет"
            name="correspondent"
            value={formData.correspondent || ''}
            onChange={handleInputChange}
            placeholder="Введите корреспондентский счет"
          />

          <InputField
            label="БИК банка"
            name="bankBic"
            value={formData.bankBic || ''}
            onChange={handleInputChange}
            placeholder="Введите корреспондентский счет"
          />
        </div>
        {errorMessage && <div className="text-[red] mb-4">{errorMessage}</div>}
        <div className="mt-[40px] w-full flex mb-[24px]">
          <Button
            text="Сохранить изменения"
            nameClass={EButtonClass.DEF}
            isLink={false}
            typeBtn={EButtonType.BUTTON}
            handle={handleSubmit}
          />
        </div>
        {isSuccessUpdate && <SuccesSmallNotification text="Данные успешно обновлены" />}
      </div>
    </PageLayout>
  );
};

export default CardDetailsPage;
