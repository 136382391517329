import { useState } from 'react';
import { BtnBack, Button, EButtonClass, EButtonType, EInputType, InputField, NoAccount } from '../../components';
import { CustomMsgs, setCookie, URLS } from '../../../utils';
import { EApiUrls } from '../../../api';
import { SESSION_ID_COOKIE } from '../../../utils/consts';

const FAIL_FETCH_SEND_PHONE = 'Ошибка отправки телефона';

export const LoginPhonePage: React.FC = () => {
  const [step, setStep] = useState('phone');
  const [code, setCode] = useState('');
  const [phone, setPhone] = useState('');
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputPhone = e.target.value;
    if (inputPhone === '' && phone === '') {
      setPhone('+7');
    } else if (!inputPhone.startsWith('+7')) {
      setPhone(`+7${inputPhone.replace(/[^\d]/g, '')}`);
    } else {
      setPhone(inputPhone);
    }
  };

  const handlePhoneSubmit = async () => {
    setErrorMessage(null);
    try {
      const response = await fetch(EApiUrls.LOGIN_PHONE, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ phone }),
      });

      const { data } = await response.json();

      if (response.ok) {
        setStep('code');

        if (data?.phpsessid) {
          setCookie(SESSION_ID_COOKIE, data.phpsessid);
        }
      } else {
        setErrorMessage(data.message || errorMessage);
      }
    } catch (error) {
      setErrorMessage(FAIL_FETCH_SEND_PHONE);
    }
  };

  const handleCodeSubmit = async () => {
    setErrorMessage(null);
    try {
      const response = await fetch(EApiUrls.CHECK_LOGIN_PHONE_CODE, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ code }),
      });

      const { data } = await response.json();

      if (response.ok) {
        if (data?.phpsessid) {
          setCookie(SESSION_ID_COOKIE, data.phpsessid);
        }
      } else {
        setErrorMessage(data.message || CustomMsgs.INVALID_CODE);
      }
    } catch (error) {
      setErrorMessage(CustomMsgs.INVALID_CODE);
    }
  };

  return (
    <div className="h-full flex flex-col justify-start items-center bg-white w-full max-w-md mx-auto pt-[64px]">
      <BtnBack />

      {step === 'phone' ? (
        <div className="relative flex flex-col w-full bg-white pt-[16px] pl-[16px] pr-[16px] pb-[48px] rounded-lg justify-between flex-grow">
          <div>
            <h2 className="text-2xl font-medium mb-8 text-[26px] text-header text-start">Вход по телефону</h2>

            <InputField
              label="Телефон"
              name="phone"
              type={EInputType.PHONE}
              placeholder="+7 (___) ___-__-__"
              value={phone}
              onChange={handlePhoneChange}
            />

            {errorMessage && <div className="text-[red] mt-4">{errorMessage}</div>}
          </div>
          <div className="w-full pt-[32px]">
            <div className="w-full mb-[12px]">
              <Button
                text="Получить код"
                nameClass={EButtonClass.DEF}
                isLink={false}
                typeBtn={EButtonType.BUTTON}
                handle={handlePhoneSubmit}
              />
            </div>
            <div className="w-full">
              <Button
                text="Войти по почте"
                nameClass={EButtonClass.SEC}
                isLink={true}
                linkUrl={URLS.LOGIN}
                typeBtn={EButtonType.BUTTON}
              />
            </div>

            <NoAccount />
          </div>
        </div>
      ) : (
        <div className="relative flex flex-col w-full bg-white pt-[16px] pb-[48px] pr-[16px] pl-[16px] rounded-lg justify-between flex-grow">
          <div>
            <h2 className="text-2xl font-medium mb-8 text-[26px] text-header text-start">Вход по телефону</h2>
            <p className="mb-4">
              Мы отправили СМС с кодом на номер: {phone}
              <span onClick={() => setStep('phone')} className="text-green cursor-pointer ml-4">
                Изменить
              </span>
            </p>

            <InputField
              label="Код"
              name="code"
              placeholder="Введите код"
              value={code}
              onChange={(e) => setCode(e.target.value)}
            />

            {errorMessage && <div className="text-[red] mt-4">{errorMessage}</div>}
          </div>
          <div className="w-full pt-[32px]">
            <div className="w-full mb-[12px]">
              <Button
                text="Войти"
                nameClass={EButtonClass.DEF}
                isLink={false}
                typeBtn={EButtonType.BUTTON}
                handle={handleCodeSubmit}
              />
            </div>
            <div className="w-full">
              <Button
                text="Войти по почте"
                nameClass={EButtonClass.SEC}
                isLink={true}
                linkUrl={URLS.LOGIN}
                typeBtn={EButtonType.BUTTON}
              />
            </div>

            <NoAccount />
          </div>
        </div>
      )}
    </div>
  );
};

export default LoginPhonePage;
