import React from 'react';
import { CrossIcon, EInputType, ImageIcon } from '.';

interface PassportUploadProps {
  uploadedFile: File | null;
  handleDrop: (event: React.DragEvent<HTMLDivElement>) => void;
  handleDragOver: (event: React.DragEvent<HTMLDivElement>) => void;
  handleFileSelect: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleRemoveFile: () => void;
}

const PassportUpload: React.FC<PassportUploadProps> = ({
  uploadedFile,
  handleDrop,
  handleDragOver,
  handleFileSelect,
  handleRemoveFile,
}) => {
  return (
    <div className="mt-[32px] mb-[40px]">
      <h2 className="text-[18px] leading-[21.6px] font-medium text-main">Подтверждение паспортных данных</h2>
      <p className="text-sm text-main mt-[16px]">
        Загрузите фотографии второй, третьей и пятой страниц страниц паспорта, чтобы наш менеджер сверил данные
      </p>
      <div className="w-1/2 flex flex-col mt-[24px] justify-center">
        <div
          onDrop={handleDrop}
          onDragOver={handleDragOver}
          className="bg-[#F8F8F8] rounded-xl p-[20px] flex flex-col justify-center items-center cursor-pointer"
        >
          <input
            type={EInputType.FILE}
            accept="image/*"
            onChange={handleFileSelect}
            className="hidden"
            id="fileUpload"
          />
          <label
            htmlFor="fileUpload"
            className="w-full h-full flex flex-col justify-center items-center cursor-pointer"
          >
            {uploadedFile ? (
              <div className="relative">
                <CrossIcon
                  className="absolute top-0 right-0 h-[14px] w-[14px] cursor-pointer self-end"
                  onClick={handleRemoveFile}
                />
                <p className="text-main text-sm pt-4 text-center">{uploadedFile.name}</p>
              </div>
            ) : (
              <>
                <ImageIcon className="h-[14px] w-[14px]" />
                <p className="text-main text-sm pt-4 text-center">Добавить фото паспорта</p>
              </>
            )}
          </label>
        </div>
      </div>
    </div>
  );
};

export default PassportUpload;
