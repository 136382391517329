import React from 'react';

interface ICatalogCardCard {
  id: number;
  price: number;
  image: string;
  name: string;
  text: string;
  index: number;
}

const CatalogCard: React.FC<ICatalogCardCard> = ({ price, image, name, index }) => {
  const isEven = index % 2 === 0;

  return (
    <div>
      <div
        className={`${
          isEven ? 'bg-red_light' : 'bg-red'
        } rounded-[12px] p-[12px] h-[100px] flex flex-col justify-between mb-[8px]`}
      >
        <div className="w-full h-[34px]">
          <img className="object-cover w-full h-full" src={image} alt={name} width={124} height={34} />
        </div>
        <div>
          <div className="text-white text-[8px] leading-[8.8px] font-[600] mb-[3px]">подарочная карта</div>
          <div className="text-white text-[14px] leading-[15.4px] font-[600]">{price} ₽</div>
        </div>
      </div>
      <div className="text-[12px] text-main mb-[4px] leading-[15.6px]">{name}</div>
      <div className="text-2xl leading-[18.9px] font-medium text-[14px] text-header">{price} баллов</div>
    </div>
  );
};

export default CatalogCard;
