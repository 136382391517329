import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  BtnBack,
  Button,
  EButtonClass,
  EButtonType,
  EInputType,
  InputField,
  InputPassword,
  NoAccount,
} from '../../components';
import { setCookie, URLS } from '../../../utils';
import CryptoJS from 'crypto-js';
import { EApiUrls } from '../../../api';
import { REMEMBER_EMAIL_KEY, SESSION_ID_COOKIE } from '../../../utils/consts';
import { HASHING } from '../../../utils/consts';
import { mutate } from 'swr';

const FAIL_FETCH: string = 'Ошибка входа. Проверьте правильность данных и попробуйте снова';

export const LoginPage: React.FC = () => {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [rememberMe, setRememberMe] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const savedEmail = localStorage.getItem(REMEMBER_EMAIL_KEY);
    if (savedEmail) {
      setEmail(savedEmail);
      setRememberMe(true);
    }
  }, []);

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const handleRememberMeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRememberMe(e.target.checked);
  };

  const handleLogin = async () => {
    setErrorMessage(null);

    if (rememberMe) {
      localStorage.setItem(REMEMBER_EMAIL_KEY, email);
    } else {
      localStorage.removeItem(REMEMBER_EMAIL_KEY);
    }

    const useHashing = HASHING === 'true';
    const finalPassword = useHashing ? CryptoJS.MD5(password).toString() : password;

    try {
      const response = await fetch(EApiUrls.LOGIN_EMAIL, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password: finalPassword }),
      });

      const { data } = await response.json();

      if (response.ok) {
        if (data?.phpsessid) {
          setCookie(SESSION_ID_COOKIE, data.phpsessid);
        }

        navigate(URLS.HOME);
        mutate(EApiUrls.POINTS);
      } else {
        setErrorMessage(data.message || FAIL_FETCH);
      }
    } catch (error) {
      setErrorMessage(FAIL_FETCH);
    }
  };

  return (
    <div className="h-full flex flex-col justify-start items-center bg-white w-full pt-[64px]">
      <BtnBack />
      <div className="w-full bg-white p-[16px] rounded-lg flex-1 flex-col justify-center">
        <h2 className="text-2xl font-medium mb-8 text-[26px] text-header">Добро пожаловать!</h2>

        <InputField
          label="Email"
          type={EInputType.EMAIL}
          name="email"
          value={email}
          onChange={handleEmailChange}
          placeholder="Введите email"
        />

        <InputPassword
          label="Пароль"
          placeholder="Введите пароль"
          value={password}
          name="password"
          onChange={handlePasswordChange}
        />

        <div className="flex items-center justify-between w-full mt-4 flex-wrap">
          <div className="flex items-center custom-checkbox">
            <input type={EInputType.CHECKBOX} id="remember-me" checked={rememberMe} onChange={handleRememberMeChange} />
            <label htmlFor="remember-me" className="text-main">
              Запомнить меня
            </label>
          </div>
          <Link to={URLS.RESET_PASS} className="items-center ml-[10px] text-green">
            Забыли пароль?
          </Link>
        </div>
        {errorMessage && <div className="text-[red] mt-4">{errorMessage}</div>}
      </div>

      <div className="w-full pt-[32px] pb-[48px] pl-[16px] pr-[16px]">
        <div className="w-full mb-[12px]">
          <Button
            text="Войти"
            nameClass={EButtonClass.DEF}
            isLink={false}
            handle={handleLogin}
            typeBtn={EButtonType.BUTTON}
          />
        </div>
        <div className="w-full">
          <Button
            text="Войти по телефону"
            nameClass={EButtonClass.SEC}
            isLink={true}
            linkUrl={URLS.SIGN_PHONE}
            typeBtn={EButtonType.BUTTON}
          />
        </div>

        <NoAccount />
      </div>
    </div>
  );
};

export default LoginPage;
