export enum URLS {
  START = '/',
  HOME = '/home',
  LOGIN = '/login',
  REGISTER = '/register',
  SIGN_PHONE = '/sign_in/phone',
  RESET_PASS = '/reset_password',
  CATALOG = '/catalog',
  PRODUCT = '/product',
  SCANNER = '/scanner',
  ACCOUNT = '/account',
  ACCOUNT_PERSONAL_INFO = '/account/personal-info',
  ACCOUNT_DOCUMENT_FLOW = '/account/document-flow',
  ACCOUNT_PASSPORT_DETAILS = '/account/passport-details',
  ACCOUNT_SNILS_INN = '/account/snils-inn',
  ACCOUNT_CARD_DETAILS = '/account/card-details',
  ACCOUNT_POINTS = '/account/points',
  ACCOUNT_CONTACTS = '/account/contacts',
  ACCOUNT_HISTORY = '/account/history',
  ACCOUNT_RULES = '/account/program_rules',
  ACCOUNT_SETTINGS = '/account/settings',
  ACCOUNT_HELP = '/account/help',
}
