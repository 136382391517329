import React from 'react';
import { IIcon } from '..';

const TimeIcon: React.FC<IIcon> = ({ color = '#4B4B4B' }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 6.54545V11.9995M12 11.9995L15.6364 14.7273M12 11.9995C12 11.9998 11.9998 11.9994 12 11.9995ZM2.25939 14.2727C2.08971 13.5426 2 12.7818 2 12C2 11.2182 2.08971 10.4574 2.25939 9.72727M10.1818 2.16489C10.7714 2.05659 11.3791 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C11.3791 22 10.7714 21.9434 10.1818 21.8351M7 3.33782C5.63602 4.12684 4.47571 5.22877 3.61721 6.54545M3.61721 17.4545C4.47571 18.7712 5.63602 19.8732 7 20.6622"
        stroke={color}
        strokeWidth="1.3"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default TimeIcon;
