import React from 'react';
import { IIcon } from '..';

const LogoIcon: React.FC<IIcon> = ({ color, className }) => {
  return (
    <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_4063_69)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 3.45757C0.0841369 4.0913 0.355245 4.93939 0.504821 5.58245C1.01899 7.82849 1.37424 10.1584 1.56121 12.479C1.9258 17.027 1.33684 18.2852 3.73942 21.5191C5.89893 24.3336 8.82502 25.8434 11.8166 27.8937C12.1344 28.1081 15.1633 29.8415 15.3316 29.8602C15.9299 29.9068 21.0062 26.6263 21.8101 26.0484C23.9323 24.52 25.7926 23.355 27.1669 21.2768C29.3451 18.0056 28.7748 16.8779 29.1114 12.5722C29.2796 10.4473 29.6816 7.71665 30.1771 5.64769C30.336 4.99531 30.6352 4.07266 30.7006 3.45757C30.0088 3.0009 28.3354 2.42308 27.4286 2.11553C19.8002 -0.438055 10.9939 -0.466014 3.32808 2.07825C2.52411 2.34853 0.570261 3.01954 0 3.45757Z"
          fill="#7BB91A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.2962 10.466C22.2401 12.1062 20.4358 11.9385 20.5947 10.5312C20.6695 9.93475 21.4081 9.32897 20.7537 8.58339C20.5106 8.3038 19.8749 7.86578 19.3981 7.67007C18.267 7.20408 16.7431 7.05497 15.4156 7.26C8.57251 8.33176 9.2643 19.2265 15.6681 20.1677C16.6777 20.3169 18.7063 20.0932 19.4075 19.562C19.4542 19.0773 19.5103 18.2758 19.5103 17.5582C19.7721 13.5321 16.2851 15.6757 16.0981 14.8369C15.9298 14.1006 16.5842 14.1286 17.1825 14.11C18.9307 14.082 19.7721 15.3029 23.5208 15.0606V15.7502L22.6982 16.095C22.2681 16.8779 22.5766 19.0773 22.6234 20.0932C20.9967 20.5778 19.6879 21.0904 17.7995 21.2768C16.0046 21.4539 14.0695 21.3793 12.4709 20.8294C9.63825 19.8602 7.41329 17.5676 7.34785 13.9236C7.22632 6.47715 14.9389 4.84621 21.5015 6.77538C21.7914 6.85926 22.0905 6.95245 22.371 7.05497C21.6137 8.92822 22.3336 9.46876 22.2962 10.4753V10.466Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_4063_69">
          <rect width="31" height="30" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LogoIcon;
