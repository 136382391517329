import React from 'react';
import { EButtonType } from '../../components';

interface IMenuOrder {
  isOpenCountOrder: boolean;
  minusCountOrder: () => void;
  plusCountOrder: () => void;
  price: number;
  countOrder: number;
}

const MenuOrder: React.FC<IMenuOrder> = (props) => {
  const { isOpenCountOrder, minusCountOrder, plusCountOrder, price, countOrder } = props;

  return (
    <div
      className={`transition-all duration-500 ease-in-out pt-[12px] bg-white ${
        isOpenCountOrder ? 'opacity-100 z-2' : 'opacity-0 overflow-hidden z-[-2] relative'
      }`}
    >
      <div
        className="flex justify-between items-center py-[12px] px-[16px] bg-light_grey rounded-[12px]"
        style={{ boxShadow: '0px 1px 2px 0px #1018280A' }}
      >
        <div className="flex gap-x-[4px] items-center">
          <button type={EButtonType.BUTTON} className="w-[24px] h-[24px]" onClick={minusCountOrder}>
            <img src="/Icons/minus.svg" alt="Minus" />
          </button>
          <div className="w-[20px] text-[16px] font-medium text-center leading-[20.8px]">{countOrder}</div>
          <button type={EButtonType.BUTTON} className="w-[24px] h-[24px]" onClick={plusCountOrder}>
            <img src="/Icons/plus.svg" alt="Plus" />
          </button>
        </div>
        <div className="text-green leading-[20.8px] text-[16px]">{price * countOrder}₽</div>
      </div>
    </div>
  );
};

export default MenuOrder;
