// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.scanner-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(100vh - 64px);
  padding-top: 64px;
}

.qr-reader {
  width: 100%;
  height: 100%;
  position: center;
}

.qr-reader > div {
  width: 100%;
  height: 100%;
  padding-top: 0 !important;
  position: relative;
}

.qr-reader video {
  width: 100% !important;
  height: 100% !important;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  transform-origin: center;
}
`, "",{"version":3,"sources":["webpack://./src/styles/QrScanner.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,WAAW;EACX,0BAA0B;EAC1B,iBAAiB;AACnB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,yBAAyB;EACzB,kBAAkB;AACpB;;AAEA;EACE,sBAAsB;EACtB,uBAAuB;EACvB,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,iBAAiB;EACjB,wBAAwB;AAC1B","sourcesContent":[".scanner-container {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  width: 100%;\n  height: calc(100vh - 64px);\n  padding-top: 64px;\n}\n\n.qr-reader {\n  width: 100%;\n  height: 100%;\n  position: center;\n}\n\n.qr-reader > div {\n  width: 100%;\n  height: 100%;\n  padding-top: 0 !important;\n  position: relative;\n}\n\n.qr-reader video {\n  width: 100% !important;\n  height: 100% !important;\n  position: absolute;\n  top: 0;\n  left: 0;\n  object-fit: cover;\n  transform-origin: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
