import React from 'react';
import { BtnBack, FailFetch, Loading, PresentIcon, ScanIcon, WalletIcon } from '.';
import { ETransactionType, ITransaction } from '../../api';
import { EFailFetch } from './FailFetch';
import { Link } from 'react-router-dom';
import { URLS } from '../../utils';

interface ITransactionsProps {
  transactions: ITransaction[] | null | undefined;
  isLoading: boolean;
  isError: boolean;
}

const Transactions: React.FC<ITransactionsProps> = ({ transactions, isLoading, isError }) => {
  if (isLoading) return <Loading />;
  if (isError) return <FailFetch text={EFailFetch.ERROR_FETCH} />;
  if (!transactions || transactions.length === 0) return <FailFetch text={EFailFetch.NOTHING_FETCH} />;

  return (
    <>
      <BtnBack />
      <div className="w-full pt-[24px] pb-[88px] px-[16px] bg-white rounded-lg flex-1 justify-center self-start">
        <h2 className="text-2xl font-medium mb-[32px] text-[24px] text-header text-start">История транзакций</h2>
        <div className="bg-gradient-to-r rounded-xl w-full">
          <div className="mb-[40px] w-full">
            <ul className="list-none p-0">
              {transactions.map((transaction) => (
                <li key={transaction.id} className="mb-[12px]">
                  <Link
                    className="flex p-[12px] rounded-xl bg-light_grey items-center justify-between"
                    to={`${URLS.PRODUCT}/${transaction.id}`}
                  >
                    <div className="flex items-center">
                      <div className="p-[8px] rounded-lg bg-white">
                        {transaction.type === ETransactionType.SCAN && <ScanIcon className="h-[14px] w-[14px]" />}
                        {transaction.type === ETransactionType.CERTIFICATE && (
                          <PresentIcon className="h-[14px] w-[14px]" />
                        )}
                        {transaction.type === ETransactionType.CASHPRIZE && (
                          <WalletIcon className="h-[14px] w-[14px]" />
                        )}
                      </div>
                      <div className="pl-[12px]">
                        <p className="font-medium text-sm mb-[4px]">
                          {transaction.type === ETransactionType.SCAN && 'Отсканирован QR-код'}
                          {transaction.type === ETransactionType.CERTIFICATE && 'Покупка сертификата'}
                          {transaction.type === ETransactionType.CASHPRIZE && 'Выплата денежного приза'}
                        </p>
                        <p className="text-xs font-normal">Дата: {transaction.date}</p>
                      </div>
                    </div>
                    <div className="px-[8px] rounded-lg items-end">
                      <p className={`font-normal text-sm ${transaction.amount > 0 ? 'text-green' : 'text-error'}`}>
                        {transaction.amount > 0 ? `${transaction.amount}` : transaction.amount}
                      </p>
                    </div>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Transactions;
