import React from 'react';
import { useParams } from 'react-router-dom';
import { BtnBack } from '../../components';
import { useGetProductCard } from '../../../api';
import ProductContent from './ProductContent';

const ProductPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { data, isLoading, isError } = useGetProductCard(Number(id));

  return (
    <div className="h-full flex flex-col justify-start items-center bg-white w-full pt-[64px]">
      <BtnBack />
      <ProductContent data={data} isLoading={isLoading} isError={isError} />
    </div>
  );
};

export default ProductPage;
