import React from 'react';
import { PUBLIC_URL, URLS } from '../../../utils';
import { Button, EButtonClass, EButtonType } from '../../components';

const StartPage: React.FC = () => {
  return (
    <div className="flex flex-col items-center w-full h-full  justify-start pt-[64px]">
      <div className="flex-1 pt-[48px]">
        <img src={`${PUBLIC_URL}/image/OnboardingImg.png`} alt="Onboarding Img" className="justify-center" />
      </div>
      <div className="bg-white pt-[48px] pb-[48px] pl-[16px] pr-[16px] rounded-lg justify-center">
        <h2 className="text-2xl font-medium mb-4 text-[26px] text-header">Сканируй, обменивай, и получай подарки</h2>
        <p className="text-base font-normal mb-[32px] text-main">
          Сканируйте QR-коды, зарабатывайте баллы и обменивайте их на подарки!
        </p>
        <div className="flex-wrap justify-center items-end gap-x-[8px]">
          <div className="w-full mb-[12px]">
            <Button
              text="Войти"
              nameClass={EButtonClass.DEF}
              isLink={true}
              linkUrl={URLS.LOGIN}
              typeBtn={EButtonType.BUTTON}
            />
          </div>
          <div className="w-full">
            <Button
              text="Зарегистрироваться"
              nameClass={EButtonClass.SEC}
              isLink={true}
              linkUrl={URLS.REGISTER}
              typeBtn={EButtonType.BUTTON}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default StartPage;
