import React from 'react';
import { PUBLIC_URL } from '../../utils';

const Loading: React.FC = () => {
  return (
    <div className="flex justify-center items-start mt-4">
      <img src={`${PUBLIC_URL}/loading.gif`} alt="Loading..." className="w-[120px] h-[120px]" />
    </div>
  );
};

export default Loading;
