import React from 'react';

interface IStepItem {
  stepName: string;
  text: string;
  icon: React.ReactElement;
}

const StepItem: React.FC<IStepItem> = ({ stepName, text, icon }) => {
  return (
    <div className="bg-light_grey flex rounded-[12px] items-center">
      <div className="w-[100px] h-[100px] flex-shrink-0 flex justify-center items-center">
        {React.cloneElement(icon, { className: 'h-[62px] w-[62px]' })}
      </div>
      <div className="py-[12px] pr-[12px]">
        <p className="mb-[4px] text-header font-medium text-[14px] leading-[18.9px]">{stepName}</p>
        <p className="text-main text-[14px] leading-[18.9px]">{text}</p>
      </div>
    </div>
  );
};

export default StepItem;
