import React, { useEffect, useState } from 'react';
import genders from '../../../staticData/gender.json';
import {
  // Button,
  // EButtonClass,
  // EButtonType,
  EInputType,
  InputField,
  InputSelect,
} from '../../components';
import { PageLayout } from '../../layout';
import {
  IGetUserInfo,
  // useGetCities,
  // useGetCountries,
  // useGetRegions,
  useGetUserInfo,
} from '../../../api';

const PersonalInfoPage: React.FC = () => {
  const { data } = useGetUserInfo();

  const [formData, setFormData] = useState<IGetUserInfo>({
    id: 0,
    name: null,
    surname: null,
    patronymic: null,
    email: null,
    avatar: null,
    gender: null,
    birthDate: null,
    contactInfo: {
      phone: null,
      address: {
        country: null,
        region: null,
        city: null,
        zip: 0,
        street: null,
        house: null,
        apartment: null,
      },
    },
    storeInfo: {
      country: null,
      region: null,
      city: null,
      address: null,
      name: null,
    },
  });

  useEffect(() => {
    if (data) {
      setFormData({
        id: data.id,
        name: data.name,
        surname: data.surname,
        patronymic: data.patronymic,
        email: data.email,
        avatar: data.avatar,
        gender: data.gender,
        birthDate: data.birthDate,
        contactInfo: {
          phone: data.contactInfo.phone,
          address: {
            country: data.contactInfo.address.country,
            region: data.contactInfo.address.region,
            city: data.contactInfo.address.city,
            zip: data.contactInfo.address.zip,
            street: data.contactInfo.address.street,
            house: data.contactInfo.address.house,
            apartment: data.contactInfo.address.apartment,
          },
        },
        storeInfo: {
          country: data.storeInfo.country,
          region: data.storeInfo.region,
          city: data.storeInfo.city,
          address: data.storeInfo.address,
          name: data.storeInfo.name,
        },
      });
    }
  }, [data]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;

    setFormData((prevData) => {
      const updatedData = { ...prevData };

      switch (name) {
        case 'name':
        case 'surname':
        case 'patronymic':
        case 'email':
        case 'avatar':
        case 'gender':
        case 'birthDate':
          updatedData[name] = value;
          break;
        case 'phone':
          updatedData.contactInfo.phone = value;
          break;
        case 'addressCountry':
          updatedData.contactInfo.address.country = value;
          updatedData.contactInfo.address.region = '';
          updatedData.contactInfo.address.city = '';
          break;
        case 'addressRegion':
          updatedData.contactInfo.address.region = value;
          updatedData.contactInfo.address.city = '';
          break;
        case 'addressCity':
          updatedData.contactInfo.address.city = value;
          break;
        case 'addressStreet':
          updatedData.contactInfo.address.street = value;
          break;
        case 'addressHouse':
          updatedData.contactInfo.address.house = value;
          break;
        case 'addressApartment':
          updatedData.contactInfo.address.apartment = value;
          break;
        case 'addressZip':
          updatedData.contactInfo.address.zip = parseInt(value, 10) || 0;
          break;
        case 'storeCountry':
          updatedData.storeInfo.country = value;
          updatedData.storeInfo.region = '';
          updatedData.storeInfo.city = '';
          break;
        case 'storeRegion':
          updatedData.storeInfo.region = value;
          updatedData.storeInfo.city = '';
          break;
        case 'storeCity':
          updatedData.storeInfo.city = value;
          break;
        case 'storeAddress':
          updatedData.storeInfo.address = value;
          break;
        case 'storeName':
          updatedData.storeInfo.name = value;
          break;
        default:
          break;
      }

      return updatedData;
    });
  };

  // const addressCountries = useGetCountries();
  // const addressRegions = useGetRegions(formData.contactInfo.address.country);
  // const addressCities = useGetCities(formData.contactInfo.address.region);
  // const storeCountries = useGetCountries();
  // const storeRegions = useGetRegions(formData.storeInfo.country);
  // const storeCities = useGetCities(formData.storeInfo.region);

  return (
    <PageLayout isBtnBack={true}>
      <div className="w-full pt-4 pb-20 px-4 bg-white rounded-lg justify-center self-start">
        <h2 className="text-2xl font-medium mb-8 text-header text-start">Персональные данные</h2>

        <div>
          <p className="text-lg font-medium mb-5 leading-6 text-header text-start">Личные данные</p>

          <InputField
            label="Имя"
            name="name"
            value={formData.name || ''}
            onChange={handleInputChange}
            placeholder="Введите имя"
          />

          <InputField
            label="Фамилия"
            name="surname"
            value={formData.surname || ''}
            onChange={handleInputChange}
            placeholder="Введите фамилию"
          />

          <InputField
            label="Отчество"
            name="patronymic"
            value={formData.patronymic || ''}
            onChange={handleInputChange}
            placeholder="Введите отчество"
          />

          <InputSelect
            label="Пол"
            name="gender"
            value={formData.gender}
            onChange={handleInputChange}
            options={genders}
          />

          <InputField
            label="Дата рождения"
            type={EInputType.DATE}
            name="birthDate"
            value={formData.birthDate || ''}
            onChange={handleInputChange}
          />
        </div>

        <div className="mt-8">
          <p className="text-lg font-medium mb-5 leading-6 text-header text-start">Контактные данные</p>

          <InputField
            label="Email"
            name="email"
            type={EInputType.EMAIL}
            value={formData.email || ''}
            onChange={handleInputChange}
            placeholder="Введите Email"
          />

          <InputField
            label="Телефон"
            name="phone"
            type={EInputType.PHONE}
            value={formData.contactInfo.phone || ''}
            onChange={handleInputChange}
            placeholder="Введите телефон"
          />
        </div>

        <div className="mt-8">
          <p className="text-lg font-medium mb-5 leading-6 text-header text-start">Адрес продавца</p>

          <InputField
            label="Страна"
            name="addressCountry"
            value={formData.contactInfo.address.country || ''}
            onChange={handleInputChange}
            readonly={true}
            placeholder="Введите страну"
          />

          <InputField
            label="Регион"
            name="addressRegion"
            value={formData.contactInfo.address.region || ''}
            onChange={handleInputChange}
            readonly={true}
            placeholder="Введите регион"
          />

          <InputField
            label="Город или населенный пункт"
            name="addressCity"
            value={formData.contactInfo.address.city || ''}
            onChange={handleInputChange}
            readonly={true}
            placeholder="Введите город или населенный пункт"
          />

          {/* <InputSelect
            label="Страна"
            name="addressCountry"
            value={formData.contactInfo.address.country}
            onChange={handleInputChange}
            options={addressCountries?.data}
          />

          <InputSelect
            label="Регион"
            name="addressRegion"
            value={formData.contactInfo.address.region}
            onChange={handleInputChange}
            options={addressRegions?.data}
          />

          <InputSelect
            label="Город или населенный пункт"
            name="addressCity"
            value={formData.contactInfo.address.city}
            onChange={handleInputChange}
            options={addressCities?.data}
          /> */}

          <InputField
            label="Улица"
            name="addressStreet"
            value={formData.contactInfo.address.street || ''}
            onChange={handleInputChange}
            placeholder="Введите улицу"
          />

          <InputField
            label="Дом"
            name="addressHouse"
            value={formData.contactInfo.address.house || ''}
            onChange={handleInputChange}
            placeholder="Введите номер дома"
          />

          <InputField
            label="Квартира"
            name="addressApartment"
            value={formData.contactInfo.address.apartment || ''}
            onChange={handleInputChange}
            placeholder="Введите номер квартиры"
          />

          <InputField
            label="Индекс"
            name="addressZip"
            value={String(formData.contactInfo.address.zip) || ''}
            onChange={handleInputChange}
            placeholder="Введите индекс"
          />
        </div>

        <div className="mt-8">
          <p className="text-lg font-medium mb-5 leading-6 text-header text-start">Адрес магазина</p>

          <InputField
            label="Страна"
            name="storeCountry"
            value={formData.storeInfo.country || ''}
            onChange={handleInputChange}
            placeholder="Введите страну"
            readonly={true}
          />

          <InputField
            label="Регион"
            name="storeRegion"
            value={formData.storeInfo.region || ''}
            onChange={handleInputChange}
            placeholder="Введите регион"
            readonly={true}
          />

          <InputField
            label="Город или населенный пункт"
            name="storeCity"
            value={formData.storeInfo.city || ''}
            onChange={handleInputChange}
            placeholder="Введите город или населенный пункт"
            readonly={true}
          />

          {/* <InputSelect
            label="Страна"
            name="storeCountry"
            value={formData.storeInfo.country}
            onChange={handleInputChange}
            options={storeCountries?.data}
          />

          <InputSelect
            label="Регион"
            name="storeRegion"
            value={formData.storeInfo.region}
            onChange={handleInputChange}
            options={storeRegions?.data}
          />

          <InputSelect
            label="Город или населенный пункт"
            name="storeCity"
            value={formData.storeInfo.city}
            onChange={handleInputChange}
            options={storeCities?.data}
          /> */}

          <InputField
            label="Адрес магазина"
            name="storeAddress"
            value={formData.storeInfo.address || ''}
            onChange={handleInputChange}
            placeholder="Введите адрес магазина"
          />

          <InputField
            label="Название магазина"
            name="storeName"
            value={formData.storeInfo.name || ''}
            onChange={handleInputChange}
            placeholder="ООО Название магазина"
          />

          {/* <div className="mt-10 w-full flex mb-6">
            <Button
              text="Хорошо"
              nameClass={EButtonClass.DEF}
              isLink={false}
              typeBtn={EButtonType.BUTTON}
            />
          </div> */}
        </div>
      </div>
    </PageLayout>
  );
};

export default PersonalInfoPage;
