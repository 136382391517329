import React from 'react';
import { IIcon } from '..';

const ImageIcon: React.FC<IIcon> = ({ color, className }) => {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1 9.18182L12.8182 21M6 14.1818L10.2929 9.88892C10.6834 9.4984 11.3166 9.4984 11.7071 9.88893L20.4465 18.6283M16.1134 5.20449H16.7952M16.1134 5.88631H16.7952M8.27273 21H13.7273C16.273 21 17.5458 21 18.5181 20.5046C19.3734 20.0688 20.0688 19.3734 20.5046 18.5181C21 17.5458 21 16.273 21 13.7273V8.27273C21 5.72703 21 4.45419 20.5046 3.48186C20.0688 2.62658 19.3734 1.93121 18.5181 1.49542C17.5458 1 16.273 1 13.7273 1H8.27273C5.72703 1 4.45419 1 3.48186 1.49542C2.62658 1.93121 1.93121 2.62658 1.49542 3.48186C1 4.45419 1 5.72703 1 8.27273V13.7273C1 16.273 1 17.5458 1.49542 18.5181C1.93121 19.3734 2.62658 20.0688 3.48186 20.5046C4.45419 21 5.72703 21 8.27273 21ZM17.3636 5.54545C17.3636 6.04753 16.9566 6.45455 16.4545 6.45455C15.9525 6.45455 15.5455 6.04753 15.5455 5.54545C15.5455 5.04338 15.9525 4.63636 16.4545 4.63636C16.9566 4.63636 17.3636 5.04338 17.3636 5.54545Z"
        stroke="#919191"
        strokeWidth="1.3"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default ImageIcon;
