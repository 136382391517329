import React from 'react';
import { IIcon } from '..';

const HomeIcon: React.FC<IIcon> = ({ color = '#B3B3B3' }) => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_4378_450)">
        <path
          d="M1.875 9.77778L11.2242 2.54479C12.1952 1.8184 13.5548 1.8184 14.5258 2.54479L23.875 9.77778M4.80833 7.50841V19.4137C4.80833 20.8421 6.01219 22 7.49722 22H8.84167H16.9083H18.2528C19.7378 22 20.9417 20.8421 20.9417 19.4137V7.50841M10.1861 22V14.2412C10.1861 13.527 10.788 12.948 11.5306 12.948H14.2194C14.962 12.948 15.5639 13.527 15.5639 14.2412V22"
          stroke={color}
          strokeWidth="1.3"
          strokeLinecap="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_4378_450">
          <rect width="24" height="24" fill="white" transform="translate(0.875)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default HomeIcon;
