import React from 'react';
import { IIcon } from '..';

const BaarcodeIcon: React.FC<IIcon> = ({ color, className }) => {
  return (
    <svg width="62" height="62" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.9999 17.8261H11.7999V45.1739H14.9999V17.8261Z" fill="#B3B3B3" />
      <path d="M31.8 17.8261H28.6V45.1739H31.8V17.8261Z" fill="#B3B3B3" />
      <path d="M50.2 17.8261H47V45.1739H50.2V17.8261Z" fill="#B3B3B3" />
      <path d="M23 17.8261H21.4V45.1739H23V17.8261Z" fill="#B3B3B3" />
      <path d="M26.2 17.8261H24.6V45.1739H26.2V17.8261Z" fill="#B3B3B3" />
      <path d="M40.6 17.8261H39V45.1739H40.6V17.8261Z" fill="#B3B3B3" />
      <path d="M43.8001 17.8261H42.2001V45.1739H43.8001V17.8261Z" fill="#B3B3B3" />
      <path d="M19.8 17.8261H17.4V45.1739H19.8V17.8261Z" fill="#B3B3B3" />
      <path d="M36.6001 17.8261H34.2001V45.1739H36.6001V17.8261Z" fill="#B3B3B3" />
      <path d="M46 13H48C52.4183 13 56 16.5817 56 21V23" stroke="#7BB91A" strokeWidth="2" strokeLinecap="round" />
      <path d="M16 13H14C9.58172 13 6 16.5817 6 21V23" stroke="#7BB91A" strokeWidth="2" strokeLinecap="round" />
      <path d="M16 50H14C9.58172 50 6 46.4183 6 42V40" stroke="#7BB91A" strokeWidth="2" strokeLinecap="round" />
      <path d="M46 50H48C52.4183 50 56 46.4183 56 42V40" stroke="#7BB91A" strokeWidth="2" strokeLinecap="round" />
    </svg>
  );
};

export default BaarcodeIcon;
