import React from 'react';
import { Link } from 'react-router-dom';

interface IContactLink {
  link: string;
  icon: React.ReactElement;
  name: string;
  description: string;
}

const ContactLink: React.FC<IContactLink> = ({ link, icon, name, description }) => {
  return (
    <Link className="flex p-[12px] rounded-xl bg-light_grey items-center justify-between" to={link}>
      <div className="flex items-center">
        <div className="p-2 rounded-lg bg-white">{React.cloneElement(icon, { className: 'h-[14px] w-[14px]' })}</div>
        <div>
          <p className="pl-[12px] text-sm font-medium">{name}</p>
          <p className="pl-[12px] text-xs font-normal">{description}</p>
        </div>
      </div>
    </Link>
  );
};

export default ContactLink;
