import React from 'react';
import { Button, EButtonClass, EButtonType } from '..';

interface IConfirmAction {
  title: string;
  btnTextSuccess: string;
  handleSuccessBtn: (value: boolean) => void;
  btnTextNegative: string;
  handleNegativeBtn: (value: boolean) => void;
  setIsOpenConfirmOrder: (value: boolean) => void;
}

const ConfirmAction: React.FC<IConfirmAction> = (props) => {
  const { title, btnTextNegative, btnTextSuccess, setIsOpenConfirmOrder, handleSuccessBtn, handleNegativeBtn } = props;

  return (
    <div className="fixed h-full w-full top-0 left-0 z-9 bg-grey">
      <div
        className="w-full bg-white absolute bottom-[88px] flex justify-center"
        style={{ borderTopLeftRadius: '12px', borderTopRightRadius: '12px' }}
      >
        <div className="max-w-[375px] w-full">
          <button
            type={EButtonType.BUTTON}
            className="w-[24px] h-[24px] absolute top-[12px] right-[12px]"
            onClick={() => setIsOpenConfirmOrder(false)}
          >
            <img src="/Icons/close.svg" alt="Close" />
          </button>
          <div className="max-w-[375px] mx-auto p-[20px]">
            <div className="text-header text-[16px] font-medium leading-[20.8px] mb-[32px]">{title}</div>
            <div className="grid grid-cols-2 gap-x-[12px]">
              <Button
                text={btnTextNegative}
                nameClass={EButtonClass.SEC}
                isLink={false}
                typeBtn={EButtonType.BUTTON}
                handle={() => handleNegativeBtn(false)}
              />
              <Button
                text={btnTextSuccess}
                nameClass={EButtonClass.DEF}
                isLink={false}
                typeBtn={EButtonType.BUTTON}
                handle={() => handleSuccessBtn(true)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmAction;
