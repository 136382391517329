import React from 'react';
import { IIcon } from '..';

const AccountIcon: React.FC<IIcon> = ({ color = '#B3B3B3' }) => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.0411 18.5397C18.6805 17.8332 18.2047 17.1705 17.6135 16.5793C14.5823 13.5481 9.66777 13.5481 6.63656 16.5793C6.04543 17.1705 5.56957 17.8332 5.209 18.5397M2.125 12C2.125 6.47715 6.60215 2 12.125 2C17.6478 2 22.125 6.47715 22.125 12C22.125 17.5228 17.6478 22 12.125 22C6.60215 22 2.125 17.5228 2.125 12ZM15.7614 10.1818C15.7614 12.1901 14.1333 13.8182 12.125 13.8182C10.1167 13.8182 8.48864 12.1901 8.48864 10.1818C8.48864 8.17351 10.1167 6.54545 12.125 6.54545C14.1333 6.54545 15.7614 8.17351 15.7614 10.1818Z"
        stroke={color}
        strokeWidth="1.3"
      />
    </svg>
  );
};

export default AccountIcon;
