import React from 'react';
import { IIcon } from '..';

const ViberIcon: React.FC<IIcon> = ({ color, className }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.39942 17.1019C2.15646 15.0066 1.72117 12.5296 2.17528 10.136C2.62938 7.74249 3.94165 5.59705 5.86566 4.10257C7.78966 2.60809 10.1931 1.86738 12.6246 2.01953C15.056 2.17169 17.3484 3.20624 19.071 4.92892C20.7937 6.6516 21.8283 8.94391 21.9805 11.3754C22.1326 13.8069 21.3919 16.2103 19.8975 18.1343C18.403 20.0583 16.2576 21.3706 13.864 21.8247C11.4705 22.2788 8.99347 21.8436 6.89814 20.6006L3.44292 21.5877C3.29996 21.6286 3.14868 21.6304 3.00476 21.5931C2.86084 21.5559 2.72951 21.4808 2.62438 21.3756C2.51925 21.2705 2.44415 21.1392 2.40685 20.9952C2.36956 20.8513 2.37143 20.7 2.41228 20.5571L3.39942 17.1019Z"
        stroke="#7BB91A"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.7364 18.6019C12.6396 18.6034 11.5532 18.3885 10.5396 17.9695C9.52601 17.5505 8.60505 16.9356 7.82949 16.1601C7.05394 15.3845 6.43904 14.4635 6.02003 13.4499C5.60103 12.4363 5.38615 11.35 5.3877 10.2532C5.39018 9.48119 5.69878 8.74167 6.24579 8.19688C6.7928 7.65209 7.53356 7.34651 8.30558 7.34717C8.43221 7.34717 8.55659 7.38064 8.66611 7.4442C8.77563 7.50776 8.8664 7.59914 8.92923 7.70909L10.1476 9.84115C10.221 9.96973 10.2589 10.1156 10.2573 10.2636C10.2558 10.4117 10.2148 10.5567 10.1386 10.6837L9.16015 12.3144C9.66354 13.4315 10.558 14.326 11.6751 14.8294L13.3059 13.851C13.4328 13.7748 13.5778 13.7338 13.7259 13.7322C13.874 13.7306 14.0198 13.7685 14.1484 13.842L16.2805 15.0603C16.3904 15.1232 16.4818 15.2139 16.5454 15.3235C16.6089 15.433 16.6424 15.5574 16.6424 15.684C16.6402 16.4551 16.3337 17.1942 15.7895 17.7406C15.2453 18.287 14.5075 18.5965 13.7364 18.6019Z"
        stroke="#7BB91A"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <mask id="path-3-inside-1_4608_2173" fill="white">
        <path d="M12.6152 4.659C12.2755 4.659 12 4.93446 12 5.27423C12 5.614 12.2755 5.88947 12.6152 5.88947C15.6453 5.88947 18.1105 8.35467 18.1105 11.3847C18.1105 11.7245 18.3859 12 18.7257 12C19.0655 12 19.3409 11.7245 19.3409 11.3847C19.3409 7.67615 16.3237 4.659 12.6152 4.659Z" />
      </mask>
      <path
        d="M12.6152 4.659C12.2755 4.659 12 4.93446 12 5.27423C12 5.614 12.2755 5.88947 12.6152 5.88947C15.6453 5.88947 18.1105 8.35467 18.1105 11.3847C18.1105 11.7245 18.3859 12 18.7257 12C19.0655 12 19.3409 11.7245 19.3409 11.3847C19.3409 7.67615 16.3237 4.659 12.6152 4.659Z"
        fill="#7BB91A"
      />
      <path
        d="M19.3409 11.3847H20.6409V11.3847L19.3409 11.3847ZM12.6152 3.359C11.5575 3.359 10.7 4.21649 10.7 5.27423H13.3C13.3 5.65243 12.9934 5.959 12.6152 5.959V3.359ZM10.7 5.27423C10.7 6.33197 11.5575 7.18947 12.6152 7.18947V4.58947C12.9934 4.58947 13.3 4.89603 13.3 5.27423H10.7ZM12.6152 7.18947C14.9273 7.18947 16.8105 9.07263 16.8105 11.3847H19.4105C19.4105 7.63671 16.3633 4.58947 12.6152 4.58947V7.18947ZM16.8105 11.3847C16.8105 12.4425 17.668 13.3 18.7257 13.3V10.7C19.1039 10.7 19.4105 11.0065 19.4105 11.3847H16.8105ZM18.7257 13.3C19.7834 13.3 20.6409 12.4425 20.6409 11.3847H18.0409C18.0409 11.0065 18.3475 10.7 18.7257 10.7V13.3ZM20.6409 11.3847C20.6409 6.95819 17.0417 3.359 12.6152 3.359V5.959C15.6058 5.959 18.0409 8.39411 18.0409 11.3848L20.6409 11.3847Z"
        fill="#7BB91A"
        mask="url(#path-3-inside-1_4608_2173)"
      />
      <path
        d="M12.6152 8.11729C13.4302 8.11729 14.1963 8.43467 14.7726 9.01094C15.3489 9.58725 15.6662 10.3535 15.6662 11.1684C15.6662 11.5082 15.9416 11.7837 16.2814 11.7837C16.6211 11.7837 16.8966 11.5082 16.8966 11.1684C16.8967 10.0248 16.4514 8.94954 15.6427 8.14087C14.834 7.33217 13.7588 6.88678 12.6152 6.88678C12.2755 6.88678 12 7.16224 12 7.50201C12 7.84179 12.2754 8.11729 12.6152 8.11729Z"
        fill="#7BB91A"
      />
      <path
        d="M12.6152 10.5616C12.9499 10.5616 13.2221 10.8338 13.2221 11.1684C13.2221 11.5082 13.4976 11.7836 13.8374 11.7836C14.1772 11.7836 14.4526 11.5082 14.4526 11.1684C14.4526 10.1553 13.6284 9.33112 12.6152 9.33112C12.2755 9.33112 12 9.60658 12 9.94635C12 10.2861 12.2754 10.5616 12.6152 10.5616Z"
        fill="#7BB91A"
      />
    </svg>
  );
};

export default ViberIcon;
