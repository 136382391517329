import React from 'react';
import { IIcon } from '..';

const TelephoneIcon: React.FC<IIcon> = ({ color, className }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.27322 9.09369C8.94657 10.4961 9.86448 11.8106 11.027 12.973C12.1894 14.1355 13.5039 15.0534 14.9063 15.7268C15.0269 15.7847 15.0873 15.8137 15.1636 15.8359C15.4348 15.915 15.7679 15.8582 15.9976 15.6937C16.0622 15.6474 16.1175 15.5921 16.2281 15.4815C16.5663 15.1433 16.7354 14.9742 16.9055 14.8636C17.5468 14.4467 18.3736 14.4467 19.0149 14.8636C19.185 14.9742 19.3541 15.1433 19.6923 15.4815L19.8808 15.6701C20.395 16.1842 20.6521 16.4413 20.7917 16.7174C21.0694 17.2665 21.0694 17.9149 20.7917 18.464C20.6521 18.7401 20.395 18.9972 19.8808 19.5113L19.7283 19.6638C19.2159 20.1762 18.9597 20.4324 18.6114 20.6281C18.2249 20.8452 17.6246 21.0013 17.1813 21C16.7818 20.9988 16.5088 20.9213 15.9627 20.7663C13.028 19.9334 10.2588 18.3617 7.94852 16.0515C5.63825 13.7412 4.06664 10.972 3.23369 8.03731C3.07869 7.49123 3.0012 7.21819 3.00001 6.81868C2.99869 6.37537 3.1548 5.77507 3.37192 5.38857C3.56758 5.04025 3.82378 4.78406 4.33617 4.27167L4.48867 4.11917C5.00282 3.60502 5.25989 3.34794 5.53599 3.2083C6.08508 2.93057 6.73353 2.93057 7.28262 3.2083C7.55872 3.34794 7.81579 3.60502 8.32994 4.11917L8.51847 4.30769C8.8567 4.64592 9.02581 4.81504 9.13638 4.9851C9.55335 5.62641 9.55335 6.45319 9.13638 7.0945C9.02581 7.26456 8.8567 7.43368 8.51847 7.77191C8.40788 7.8825 8.35258 7.93779 8.3063 8.00243C8.14182 8.23214 8.08503 8.56519 8.16409 8.83642C8.18634 8.91275 8.2153 8.97306 8.27322 9.09369Z"
        stroke="#7BB91A"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default TelephoneIcon;
