import useSWR from 'swr';
import { EApiUrls, IApiResponse, IApiResponseReturn, IGetUserInfo, OPTIONS } from '../interfaces';
import { fetcher } from '../fetcher';
import { useHandleUnauthorized } from '..';

const useGetUserInfo = (): IApiResponseReturn<IGetUserInfo> => {
  const { data, error } = useSWR<IApiResponse<IGetUserInfo>>(EApiUrls.GET_USER_INFO, fetcher, OPTIONS);

  useHandleUnauthorized(data?.code || null);

  if (error) {
    console.error('Ошибка при загрузке персональных данных: ', error);
  }

  return {
    data: data?.data || null,
    code: data?.code || null,
    message: data?.message || null,
    time: data?.time || null,
    isLoading: !error && !data,
    isError: !!error,
  };
};

export default useGetUserInfo;
