import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowRightIcon, Button, CoinsIcon, EButtonClass, EButtonType } from '../../components';
import { PUBLIC_URL, URLS } from '../../../utils';
import { PageLayout } from '../../layout';
import { useGetPoints } from '../../../api';

const PointsPage: React.FC = () => {
  const { data: pointsData } = useGetPoints();

  return (
    <PageLayout isBtnBack={true}>
      <div className="w-full bg-white pt-[26px] px-[16px] rounded-lg flex-1 flex-col justify-center">
        <h2 className="text-2xl font-medium mb-[32px] text-[24px] text-header text-start">Баллы</h2>

        <div className="flex mt-[20px] justify-between bg-gradient-to-r from-[#95C943] to-[#7BB91A] rounded-[12px] p-[16px] h-[146px]">
          <div className="flex flex-col justify-between max-w-[130px]">
            <p className="text-[14px] leading-[18.9px] text-white font-medium mb-[4px]">Актуальных баллов на счёту</p>
            <div className="text-white text-[32px] leading-[40px] mb-[12px]">{pointsData?.points || 0}</div>
            <div className="text-white flex bg-green_light rounded-[8px] text-[12px] leading-[15.6px] items-center py-[4px] px-[8px]">
              <CoinsIcon className="h-[14px] w-[14px]" />
              <p>1 балл = 1 рубль</p>
            </div>
          </div>
          <div>
            <img
              src={`${PUBLIC_URL}/image/CoinsImg.png`}
              alt="Onboarding Img"
              className="justify-center"
              width={145}
              height={110}
              draggable="false"
            />
          </div>
        </div>
        <Link
          className="flex p-[12px] mt-[32px] rounded-xl bg-light_grey items-center justify-between"
          to={URLS.ACCOUNT_RULES}
        >
          <div className="flex items-center">Правила мотивационной программы</div>
          <div className="rounded-lg items-end">
            <ArrowRightIcon className="h-[14px] w-[14px]" />
          </div>
        </Link>
      </div>
      <div className="w-full pt-[32px] pb-[112px] pl-[16px] pr-[16px]">
        <Button
          text="Обменять баллы на призы"
          nameClass={EButtonClass.DEF}
          isLink={true}
          linkUrl={URLS.CATALOG}
          typeBtn={EButtonType.BUTTON}
        />
      </div>
    </PageLayout>
  );
};

export default PointsPage;
