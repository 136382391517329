import React from 'react';
import { Link } from 'react-router-dom';
import { EButtonType } from '..';
import { URLS } from '../../../utils';

interface IWarningCompleteProfile {
  setIsOpenWarningCompleteProfile: (value: boolean) => void;
  isNoAuth?: boolean;
}

const WarningCompleteProfile: React.FC<IWarningCompleteProfile> = ({
  setIsOpenWarningCompleteProfile,
  isNoAuth = false,
}) => {
  return (
    <div className="fixed h-full w-full top-0 left-0 z-9 bg-grey">
      <div className="w-[calc(100%-32px)] max-w-[343px] mx-auto bg-white rounded-[12px] p-[20px] flex gap-x-[8px] absolute bottom-[112px] left-[16px] right-[16px]">
        <button
          type={EButtonType.BUTTON}
          className="w-[24px] h-[24px] absolute top-[12px] right-[12px]"
          onClick={() => setIsOpenWarningCompleteProfile(false)}
        >
          <img src="/Icons/close.svg" alt="Close" />
        </button>
        <div className="w-[24px] h-[24px] flex-shrink-0">
          <img src="/Icons/completeProfile.svg" alt="Warning" />
        </div>
        <div>
          <div className="text-header text-[16px] font-medium leading-[20.8px] mb-[4px]"></div>
          <div className="text-main text-[14px] leading-[18.9px] mb-[12px]">
            {isNoAuth
              ? 'Чтобы получить приз, необходимо заполнить все поля в личном кабинете и прикрепить необходимые документы.'
              : 'Необходите авторизоваться'}
          </div>
          <Link
            to={isNoAuth ? URLS.LOGIN : URLS.ACCOUNT}
            className="text-green font-medium text-[16px] leading-[20.8px]"
          >
            {isNoAuth ? 'Заполнить' : 'Перейти'}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default WarningCompleteProfile;
