import React from 'react';
import { IIcon } from '..';

const CatalogIcon: React.FC<IIcon> = ({ color = '#B3B3B3' }) => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.375 3.6C2.375 3.03995 2.375 2.75992 2.48399 2.54601C2.57987 2.35785 2.73285 2.20487 2.92101 2.10899C3.13492 2 3.41495 2 3.975 2H8.775C9.33505 2 9.61508 2 9.82899 2.10899C10.0172 2.20487 10.1701 2.35785 10.266 2.54601C10.375 2.75992 10.375 3.03995 10.375 3.6V8.4C10.375 8.96005 10.375 9.24008 10.266 9.45399C10.1701 9.64215 10.0172 9.79513 9.82899 9.89101C9.61508 10 9.33505 10 8.775 10H3.975C3.41495 10 3.13492 10 2.92101 9.89101C2.73285 9.79513 2.57987 9.64215 2.48399 9.45399C2.375 9.24008 2.375 8.96005 2.375 8.4V3.6Z"
        stroke={color}
        strokeWidth="1.3"
      />
      <path
        d="M2.375 15.6C2.375 15.0399 2.375 14.7599 2.48399 14.546C2.57987 14.3578 2.73285 14.2049 2.92101 14.109C3.13492 14 3.41495 14 3.975 14H8.775C9.33505 14 9.61508 14 9.82899 14.109C10.0172 14.2049 10.1701 14.3578 10.266 14.546C10.375 14.7599 10.375 15.0399 10.375 15.6V20.4C10.375 20.9601 10.375 21.2401 10.266 21.454C10.1701 21.6422 10.0172 21.7951 9.82899 21.891C9.61508 22 9.33505 22 8.775 22H3.975C3.41495 22 3.13492 22 2.92101 21.891C2.73285 21.7951 2.57987 21.6422 2.48399 21.454C2.375 21.2401 2.375 20.9601 2.375 20.4V15.6Z"
        stroke={color}
        strokeWidth="1.3"
      />
      <path
        d="M14.375 3.6C14.375 3.03995 14.375 2.75992 14.484 2.54601C14.5799 2.35785 14.7328 2.20487 14.921 2.10899C15.1349 2 15.4149 2 15.975 2H20.775C21.3351 2 21.6151 2 21.829 2.10899C22.0172 2.20487 22.1701 2.35785 22.266 2.54601C22.375 2.75992 22.375 3.03995 22.375 3.6V8.4C22.375 8.96005 22.375 9.24008 22.266 9.45399C22.1701 9.64215 22.0172 9.79513 21.829 9.89101C21.6151 10 21.3351 10 20.775 10H15.975C15.4149 10 15.1349 10 14.921 9.89101C14.7328 9.79513 14.5799 9.64215 14.484 9.45399C14.375 9.24008 14.375 8.96005 14.375 8.4V3.6Z"
        stroke={color}
        strokeWidth="1.3"
      />
      <path
        d="M14.375 15.6C14.375 15.0399 14.375 14.7599 14.484 14.546C14.5799 14.3578 14.7328 14.2049 14.921 14.109C15.1349 14 15.4149 14 15.975 14H20.775C21.3351 14 21.6151 14 21.829 14.109C22.0172 14.2049 22.1701 14.3578 22.266 14.546C22.375 14.7599 22.375 15.0399 22.375 15.6V20.4C22.375 20.9601 22.375 21.2401 22.266 21.454C22.1701 21.6422 22.0172 21.7951 21.829 21.891C21.6151 22 21.3351 22 20.775 22H15.975C15.4149 22 15.1349 22 14.921 21.891C14.7328 21.7951 14.5799 21.6422 14.484 21.454C14.375 21.2401 14.375 20.9601 14.375 20.4V15.6Z"
        stroke={color}
        strokeWidth="1.3"
      />
    </svg>
  );
};

export default CatalogIcon;
