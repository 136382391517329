import React from 'react';
import { Link } from 'react-router-dom';
import { EButtonClass, EButtonType } from '..';

interface IButton {
  text: string;
  nameClass: EButtonClass;
  isLink: boolean;
  linkUrl?: string;
  typeBtn?: EButtonType;
  isExternal?: boolean;
  isDisabled?: boolean;
  handle?: () => void;
}

const Button: React.FC<IButton> = (props) => {
  const { text, nameClass, isLink, linkUrl, isExternal, isDisabled, handle } = props;

  let customNameClass =
    'flex justify-center align-center w-full p-[12px] text-[16px] h-[48px] font-medium rounded-[12px] ';

  switch (nameClass) {
    case EButtonClass.DEF:
      customNameClass += ' text-white bg-green';
      break;
    case EButtonClass.SEC:
      customNameClass += ' text-green bg-white border border-green';
      break;
    case EButtonClass.TRE:
      customNameClass += ' text-black bg-white';
      break;
    default:
      break;
  }

  if (isDisabled) {
    customNameClass += ' bg-light_grey text-[#AAAAAA] cursor-not-allowed';
  }

  if (isLink && linkUrl) {
    return (
      <Link to={linkUrl} className={customNameClass} target={isExternal ? '_blank' : '_self'}>
        {text}
      </Link>
    );
  }

  return (
    <button className={customNameClass} disabled={isDisabled} onClick={handle}>
      {text}
    </button>
  );
};

export default Button;
