import React from 'react';
import { IIcon } from '..';

const CrossIcon: React.FC<IIcon> = ({ color, className, onClick }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
    >
      <path d="M18 6L6 18M18 18L6 6.00001" stroke="#4B4B4B" strokeWidth="1.3" strokeLinecap="round" />
    </svg>
  );
};

export default CrossIcon;
