import React, { useEffect, useState } from 'react';
import { Button, EButtonClass, EButtonType, InputField, SuccesSmallNotification } from '../../components';
import { PageLayout } from '../../layout';
import { EApiUrls, ISnilsInn, useGetSnilsInn } from '../../../api';
import { mutate } from 'swr';
import { URLS } from '../../../utils';
import { useNavigate } from 'react-router-dom';

const FAIL_FETCH: string =
  'Ошибка при обновлении данных СНИЛС и ИНН. Проверьте правильность данных и попробуйте снова.';

const SnilsInnPage: React.FC = () => {
  const [isSuccessUpdate, setIsSuccessUpdate] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [formData, setFormData] = useState<ISnilsInn>({
    snils: null,
    inn: null,
  });

  const { data } = useGetSnilsInn();
  const navigate = useNavigate();

  useEffect(() => {
    if (data) {
      setFormData({
        snils: data.snils,
        inn: data.inn,
      });
    }
  }, [data]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value || null,
    }));
  };

  const handleSubmit = async () => {
    setErrorMessage(null);
    try {
      const response = await fetch(EApiUrls.UPDATE_SNILS, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          snils: formData.snils,
          inn: formData.inn,
        }),
      });

      const result = await response.json();

      if (response.ok) {
        mutate(EApiUrls.GET_SNILS_INN);
        setIsSuccessUpdate(true);
        setTimeout(() => navigate(URLS.ACCOUNT), 2000);
      } else {
        setErrorMessage(result.message || FAIL_FETCH);
      }
    } catch (error) {
      setErrorMessage(FAIL_FETCH);
    }
  };

  return (
    <PageLayout isBtnBack={true}>
      <div className="w-full bg-white pt-[26px] px-[16px] rounded-lg flex-1 flex-col justify-center">
        <h2 className="text-2xl font-medium mb-8 text-[24px] text-header text-start">СНИЛС и ИНН</h2>
        <div>
          <InputField
            label="СНИЛС"
            name="snils"
            value={formData.snils || ''}
            onChange={handleInputChange}
            placeholder="Введите СНИЛС"
          />

          <InputField
            label="ИИН"
            name="inn"
            value={formData.inn || ''}
            onChange={handleInputChange}
            placeholder="Введите ИНН"
          />
        </div>
        {errorMessage && <div className="text-[red] mb-4">{errorMessage}</div>}
      </div>
      <div className="w-full pt-[32px] pb-[112px] pl-[16px] pr-[16px]">
        <Button
          text="Сохранить изменения"
          nameClass={EButtonClass.DEF}
          isLink={false}
          typeBtn={EButtonType.BUTTON}
          handle={handleSubmit}
        />
      </div>
      {isSuccessUpdate && <SuccesSmallNotification text="Данные успешно обновлены" />}
    </PageLayout>
  );
};

export default SnilsInnPage;
