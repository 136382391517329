import React from 'react';
import { Link } from 'react-router-dom';
import CatalogCard from '../../components/CatalogCard';
import { ICatalogCard } from '../../../api';
import { FailFetch, Loading } from '../../components';
import { EFailFetch } from '../../components/FailFetch';

interface ICertificatesCategory {
  data: ICatalogCard[] | null | undefined;
  isLoading: boolean;
  isError: boolean;
}

const CertificatesCategory: React.FC<ICertificatesCategory> = ({ data, isLoading, isError }) => {
  if (isLoading) return <Loading />;
  if (isError) return <FailFetch text={EFailFetch.ERROR_FETCH} />;
  if (!data || data.length === 0) return <FailFetch text={EFailFetch.NOTHING_FETCH} />;

  return (
    <div className="grid grid-cols-2 gap-[20px] pr-[16px] pl-[16px] pb-[112px]">
      {data.map((card, index) => (
        <Link key={card.id} to={`/product/${card.id}`}>
          <CatalogCard {...card} index={index} />
        </Link>
      ))}
    </div>
  );
};

export default CertificatesCategory;
