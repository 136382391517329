import React from 'react';
import { IIcon } from '..';

const TelegramIcon: React.FC<IIcon> = ({ color, className }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.25012 12.6443L16.6799 20.0625C16.7774 20.1483 16.8956 20.2073 17.0228 20.2337C17.15 20.2602 17.2819 20.2531 17.4055 20.2132C17.5292 20.1734 17.6403 20.102 17.7281 20.0063C17.8159 19.9105 17.8774 19.7936 17.9064 19.667L21.4327 4.27953C21.4632 4.14649 21.4568 4.00765 21.4142 3.87798C21.3716 3.7483 21.2944 3.63271 21.191 3.54365C21.0876 3.4546 20.9618 3.39545 20.8272 3.37259C20.6927 3.34973 20.5544 3.36403 20.4274 3.41394L3.12517 10.2112C2.97438 10.2705 2.84683 10.3769 2.76159 10.5147C2.67636 10.6525 2.63803 10.8142 2.65235 10.9756C2.66666 11.1369 2.73285 11.2893 2.841 11.41C2.94915 11.5306 3.09346 11.613 3.25233 11.6447L8.25012 12.6443Z"
        stroke="#7BB91A"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.25 12.6443L21.0105 3.42834"
        stroke="#7BB91A"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.46 16.3491L9.53033 19.2788C9.42544 19.3837 9.2918 19.4551 9.14632 19.4841C9.00083 19.513 8.85003 19.4982 8.71299 19.4414C8.57594 19.3846 8.45881 19.2885 8.3764 19.1652C8.29399 19.0418 8.25 18.8968 8.25 18.7485V12.6443"
        stroke="#7BB91A"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default TelegramIcon;
