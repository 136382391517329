import React from 'react';
import { Link } from 'react-router-dom';
import { BaarcodeIcon, CoinsIcon, ExchangeIcon, PhoneIcon, ScanPhoneIcon, StepItem } from '../../components';
import { PUBLIC_URL, URLS } from '../../../utils';
import { useGetPoints } from '../../../api';

const HomePage: React.FC = () => {
  const { data: pointsData } = useGetPoints();

  return (
    <div className="h-full flex flex-col justify-start items-center bg-white w-full">
      <div className="w-full bg-white pt-[64px] px-[16px] pb-[88px] flex flex-1 flex-col justify-center flex-grow">
        <div className="flex flex-col pt-[32px] w-full">
          <h2 className="mb-[20px] text-header text-[20px] leading-[24px]">Баллы</h2>
          <div className="flex justify-between bg-gradient-to-r from-[#95C943] to-[#7BB91A] rounded-[12px] p-[16px] mb-[32px] h-[146px]">
            <div className="flex flex-col justify-between max-w-[130px]">
              <p className="text-[14px] leading-[18.9px] text-white font-medium mb-[4px]">Актуальных баллов на счёту</p>
              <div className="text-white text-[32px] leading-[40px] mb-[12px]">{pointsData?.points || 0}</div>
              <div className="text-white flex bg-green_light rounded-[8px] text-[12px] leading-[15.6px] items-center py-[4px] px-[8px]">
                <CoinsIcon className="h-[14px] w-[14px]" />
                <p>1 балл = 1 рубль</p>
              </div>
            </div>
            <div>
              <img
                src={`${PUBLIC_URL}/image/CoinsImg.png`}
                alt="Onboarding Img"
                className="justify-center"
                width={145}
                height={110}
                draggable="false"
              />
            </div>
          </div>
        </div>
        <div className="flex flex-start items-center flex-wrap mb-[40px]">
          <h2 className="text-[20px] leading-[24px] text-header mb-[20px]">Продавай Gross Aqua и зарабатывай</h2>
          <div className="flex flex-col gap-y-[12px] mb-[20px]">
            <StepItem
              stepName="Шаг 1"
              text="Перейдите на вкладку «Сканер» и разрешите доступ к камере для сканирования"
              icon={<PhoneIcon />}
            />
            <StepItem
              stepName="Шаг 2"
              text="Отсканируйте QR-код, который содержится на стикере внутри упаковки"
              icon={<BaarcodeIcon />}
            />
            <StepItem
              stepName="Шаг 3"
              text="Получите баллы за каждый отсканированный QR-код"
              icon={<ScanPhoneIcon />}
            />
            <StepItem
              stepName="Шаг 4"
              text="Обменяйте накопленные баллы на сертификат или денежный приз"
              icon={<ExchangeIcon />}
            />
          </div>
          <Link to={URLS.ACCOUNT_RULES} className="flex items-center bg-light_grey rounded-[12px] p-[12px]">
            <span className="text-header text-[16px] leading-[20.8px]">Правила мотивационной программы</span>
            <img src="Icons/arrow-right.svg" alt="Перейти" className="w-[24px] h-[24px]" />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
