import React from 'react';

export enum EFailFetch {
  ERROR_FETCH = 'Ошибка сервера. Попробуйте попытку позже!',
  NOTHING_FETCH = 'Ничего не найдено. Попробуйте попытку позже!',
}

interface IFailFetch {
  text: EFailFetch;
}

const FailFetch: React.FC<IFailFetch> = ({ text }) => {
  return (
    <div className="mx-[16px] w-[100% - 32px] mt-4">
      <p>{text}</p>
    </div>
  );
};

export default FailFetch;
