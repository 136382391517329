import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Footer, Header } from './app/layout';
import { URLS } from './utils';
import {
  AccountLayout,
  CardDetailsPage,
  CatalogPage,
  ChangePasswordPage,
  ContactsPage,
  DocumentFlowPage,
  HistoryPage,
  HomePage,
  LoginPage,
  LoginPhonePage,
  PassportDetailsPage,
  PersonalInfoPage,
  PointsPage,
  ProductPage,
  ProgramRulesPage,
  RegisterPage,
  ResetPasswordPage,
  ScannerPage,
  SnilsInnPage,
  StartPage,
} from './app/pages';
import { ProtectedRouteNoUser, ProtectedRouteUser, ScrollToTop } from './app/components';

const App: React.FC = () => {
  return (
    <Router>
      <ScrollToTop />
      <div className="App h-screen w-full">
        <Header />
        <Routes>
          <Route
            path={URLS.START}
            element={
              <ProtectedRouteUser>
                <StartPage />
              </ProtectedRouteUser>
            }
          />
          <Route
            path={URLS.LOGIN}
            element={
              <ProtectedRouteUser>
                <LoginPage />
              </ProtectedRouteUser>
            }
          />
          <Route
            path={URLS.REGISTER}
            element={
              <ProtectedRouteUser>
                <RegisterPage />
              </ProtectedRouteUser>
            }
          />
          <Route
            path={URLS.SIGN_PHONE}
            element={
              <ProtectedRouteUser>
                <LoginPhonePage />
              </ProtectedRouteUser>
            }
          />
          <Route
            path={URLS.RESET_PASS}
            element={
              <ProtectedRouteUser>
                <ResetPasswordPage />
              </ProtectedRouteUser>
            }
          />
          <Route
            path={URLS.SCANNER}
            element={
              <ProtectedRouteNoUser>
                <ScannerPage />
              </ProtectedRouteNoUser>
            }
          />
          <Route
            path={URLS.ACCOUNT}
            element={
              <ProtectedRouteNoUser>
                <AccountLayout />
              </ProtectedRouteNoUser>
            }
          />
          <Route
            path={URLS.ACCOUNT_PASSPORT_DETAILS}
            element={
              <ProtectedRouteNoUser>
                <PassportDetailsPage />
              </ProtectedRouteNoUser>
            }
          />
          <Route
            path={URLS.ACCOUNT_SNILS_INN}
            element={
              <ProtectedRouteNoUser>
                <SnilsInnPage />
              </ProtectedRouteNoUser>
            }
          />
          <Route
            path={URLS.ACCOUNT_CARD_DETAILS}
            element={
              <ProtectedRouteNoUser>
                <CardDetailsPage />
              </ProtectedRouteNoUser>
            }
          />
          <Route
            path={URLS.ACCOUNT_PERSONAL_INFO}
            element={
              <ProtectedRouteNoUser>
                <PersonalInfoPage />
              </ProtectedRouteNoUser>
            }
          />
          <Route
            path={URLS.ACCOUNT_DOCUMENT_FLOW}
            element={
              <ProtectedRouteNoUser>
                <DocumentFlowPage />
              </ProtectedRouteNoUser>
            }
          />
          <Route
            path={URLS.ACCOUNT_POINTS}
            element={
              <ProtectedRouteNoUser>
                <PointsPage />
              </ProtectedRouteNoUser>
            }
          />
          <Route
            path={URLS.ACCOUNT_CONTACTS}
            element={
              <ProtectedRouteNoUser>
                <ContactsPage />
              </ProtectedRouteNoUser>
            }
          />
          <Route
            path={URLS.ACCOUNT_HISTORY}
            element={
              <ProtectedRouteNoUser>
                <HistoryPage />
              </ProtectedRouteNoUser>
            }
          />
          <Route
            path={URLS.ACCOUNT_SETTINGS}
            element={
              <ProtectedRouteNoUser>
                <ChangePasswordPage />
              </ProtectedRouteNoUser>
            }
          />
          <Route
            path={URLS.CATALOG}
            element={
              // <ProtectedRouteNoUser>
              <CatalogPage />
              // </ProtectedRouteNoUser>
            }
          />
          <Route
            path={`${URLS.PRODUCT}/:id`}
            element={
              <ProtectedRouteNoUser>
                <ProductPage />
              </ProtectedRouteNoUser>
            }
          />
          <Route path={URLS.HOME} element={<HomePage />} />
          <Route path={URLS.ACCOUNT_RULES} element={<ProgramRulesPage />} />
          <Route path="*" element={<HomePage />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
