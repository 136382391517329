import React, { useState } from 'react';
import {
  BtnBack,
  Button,
  CheckboxInput,
  EButtonClass,
  EButtonType,
  EInputType,
  InputField,
  InputPassword,
  InputSelect,
  SuccesSmallNotification,
} from '../../components';
import { CustomErrorsMsgs, setCookie, URLS } from '../../../utils';
import { EApiUrls, useGetCities, useGetCountries, useGetRegions } from '../../../api';
import CryptoJS from 'crypto-js';
import { HASHING, SESSION_ID_COOKIE } from '../../../utils/consts';
import { useNavigate } from 'react-router-dom';

const FAIL_FETCH = 'Произошла ошибка при регистрации.  Проверьте правильность данных и попробуйте снова.';

export const RegisterPage: React.FC = () => {
  const [formData, setFormData] = useState<Record<string, string>>({
    name: '',
    surname: '',
    patronymic: '',
    email: '',
    phone: '',
    country: '',
    region: '',
    city: '',
    shopAddress: '',
    shopName: '',
    password: '',
    confirmPassword: '',
  });

  const [isSuccessRegister, setIsSuccessRegister] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [checkboxes, setCheckboxes] = useState({
    checkbox1: false,
    checkbox2: false,
    checkbox3: false,
  });

  const navigate = useNavigate();

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;

    let updatedValue = value;
    if (name === 'phone') {
      if (!value || value.replace(/\D+/g, '').length === 0) {
        updatedValue = '+7';
      } else if (!value.startsWith('+7')) {
        updatedValue = `+7${value.replace(/[^\d]/g, '')}`;
      }
    }

    setFormData((prevData) => ({
      ...prevData,
      [name]: updatedValue,
      ...(name === 'country' ? { region: '', city: '' } : {}),
      ...(name === 'region' ? { city: '' } : {}),
    }));
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, checked } = e.target;
    setCheckboxes((prev) => ({
      ...prev,
      [id]: checked,
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    setErrorMessage(null);

    if (formData.password !== formData.confirmPassword) {
      setErrorMessage(CustomErrorsMsgs.PASSWORD_NO_MATCH);
      setIsSubmitting(false);
      return;
    }

    const useHashing = HASHING === 'true';
    const finalPassword = useHashing ? CryptoJS.MD5(formData.password).toString() : formData.password;
    const finalConfirmPassword = useHashing
      ? CryptoJS.MD5(formData.confirmPassword).toString()
      : formData.confirmPassword;

    try {
      const response = await fetch(EApiUrls.REGISTER, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: formData.name,
          surname: formData.surname,
          patronymic: formData.patronymic,
          email: formData.email,
          phone: formData.phone,
          country: formData.country,
          region: formData.region,
          city: formData.city,
          shop_address: formData.shopAddress,
          shop_name: formData.shopName,
          password: finalPassword,
          confirm_password: finalConfirmPassword,
        }),
      });

      const { data } = await response.json();

      if (response.ok) {
        if (data?.phpsessid) {
          setCookie(SESSION_ID_COOKIE, data.phpsessid);
        }

        setIsSuccessRegister(true);
        setTimeout(() => navigate(URLS.LOGIN), 2000);
      } else {
        setErrorMessage(data.message || FAIL_FETCH);
      }
    } catch (error) {
      setErrorMessage(FAIL_FETCH);
    } finally {
      setIsSubmitting(false);
    }
  };

  const countries = useGetCountries();
  const regions = useGetRegions(formData.country);
  const cities = useGetCities(formData.region);

  return (
    <div className="h-full flex flex-col justify-start items-center bg-white w-full pt-[64px]">
      <BtnBack />
      <div className="relative w-full bg-white pb-[48px] pl-[16px] pr-[16px] pt-[16px] rounded-lg justify-center self-start">
        <h2 className="text-2xl font-medium mb-8 text-[26px] text-header text-start">Регистрация</h2>

        <form onSubmit={handleSubmit}>
          <InputField
            label="Имя"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            placeholder="Введите имя"
          />

          <InputField
            label="Фамилия"
            name="surname"
            value={formData.surname}
            onChange={handleInputChange}
            placeholder="Введите фамилию"
          />

          <InputField
            label="Отчество"
            name="patronymic"
            value={formData.patronymic}
            onChange={handleInputChange}
            placeholder="Введите отчество"
          />

          <InputField
            label="Email"
            type={EInputType.EMAIL}
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            placeholder="Введите email"
          />

          <InputField
            label="Телефон"
            name="phone"
            value={formData.phone}
            onChange={handleInputChange}
            placeholder="+7"
          />

          <InputSelect
            label="Страна"
            name="country"
            value={formData.country}
            onChange={handleInputChange}
            options={countries?.data}
          />

          <InputSelect
            label="Регион"
            name="region"
            value={formData.region}
            onChange={handleInputChange}
            options={regions?.data}
          />

          <InputSelect
            label="Город"
            name="city"
            value={formData.city}
            onChange={handleInputChange}
            options={cities?.data}
          />

          <InputField
            label="Адрес магазина"
            name="shopAddress"
            value={formData.shopAddress}
            onChange={handleInputChange}
            placeholder="Введите адрес магазина"
          />

          <InputField
            label="Название магазина"
            name="shopName"
            value={formData.shopName}
            onChange={handleInputChange}
            placeholder="ООО Название магазина"
          />

          <InputPassword
            label="Пароль"
            placeholder="Введите пароль"
            value={formData.password}
            name="password"
            onChange={handleInputChange}
          />

          <InputPassword
            label="Подтвердите пароль"
            placeholder="Введите пароль"
            value={formData.confirmPassword}
            name="confirmPassword"
            onChange={handleInputChange}
          />

          <div className="flex-wrap justify-center w-full pt-5">
            <div className="flex flex-wrap justify-between items-center gap-[12px] mb-8">
              <CheckboxInput
                label="Подтверждение согласия с политикой конфиденциальности и обработкой персональных данных"
                id="checkbox1"
                checked={checkboxes.checkbox1}
                onChange={handleCheckboxChange}
              />
              <CheckboxInput
                label="Подтверждение согласия с правилами мотивационной программы"
                id="checkbox2"
                checked={checkboxes.checkbox2}
                onChange={handleCheckboxChange}
              />
              <CheckboxInput
                label="Согласие на получение рассылок"
                id="checkbox3"
                checked={checkboxes.checkbox3}
                onChange={handleCheckboxChange}
              />
            </div>

            {errorMessage && <div className="text-[red] mb-4">{errorMessage}</div>}

            <div className="w-full mb-[12px]">
              <Button
                text="Зарегистрироваться"
                nameClass={EButtonClass.DEF}
                typeBtn={EButtonType.SUBMIT}
                isDisabled={isSubmitting || !checkboxes.checkbox1 || !checkboxes.checkbox2 || !checkboxes.checkbox3}
                isLink={false}
              />
            </div>
          </div>
        </form>
      </div>

      {isSuccessRegister && (
        <SuccesSmallNotification text="Пользователь успешно зарегистрирован. Необходимо авторизоваться" />
      )}
    </div>
  );
};

export default RegisterPage;
