import React, { useState, useEffect } from 'react';
import genders from '../../../staticData/gender.json';
import {
  Button,
  EButtonClass,
  EButtonType,
  EInputType,
  InputField,
  InputSelect,
  PassportUpload,
  SuccesSmallNotification,
} from '../../components';
import { PageLayout } from '../../layout';
import { EApiUrls, IPassport, useGetPassport } from '../../../api';
import { mutate } from 'swr';
import { URLS } from '../../../utils';
import { useNavigate } from 'react-router-dom';

const FAIL_FETCH: string = 'Ошибка при обновлении паспортных данных. Проверьте правильность данных и попробуйте снова';

const PassportDetailsPage: React.FC = () => {
  const [isSuccessUpdate, setIsSuccessUpdate] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const { data } = useGetPassport();
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const navigate = useNavigate();

  const [formData, setFormData] = useState<IPassport>({
    name: null,
    surname: null,
    patronymic: null,
    birthPlace: null,
    gender: null,
    birthDate: null,
    passportNumber: null,
    issuedBy: null,
    departamentCode: null,
    issueDate: null,
    registrationAddress: null,
    imagesPassport: [null],
  });

  useEffect(() => {
    if (data) {
      setFormData({
        name: data.name,
        surname: data.surname,
        patronymic: data.patronymic,
        birthPlace: data.birthPlace,
        gender: data.gender,
        birthDate: data.birthDate,
        passportNumber: data.passportNumber,
        issuedBy: data.issuedBy,
        departamentCode: data.departamentCode,
        issueDate: data.issueDate,
        registrationAddress: data.registrationAddress,
        imagesPassport: [String(data.imagesPassport)],
      });
    }
  }, [data]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files[0]) {
      setUploadedFile(files[0]);
      setFormData((prevData) => ({
        ...prevData,
        imagesPassport: [files[0].name],
      }));
    }
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    if (files && files[0]) {
      setUploadedFile(files[0]);
      setFormData((prevData) => ({
        ...prevData,
        imagesPassport: [files[0].name],
      }));
    }
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const handleRemoveFile = () => {
    setUploadedFile(null);
    setFormData((prevData) => ({
      ...prevData,
      imagesPassport: [null],
    }));
  };

  const handleSubmit = async () => {
    setErrorMessage(null);
    try {
      const response = await fetch(EApiUrls.UPDATE_PASSPORT, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: formData.name,
          surname: formData.surname,
          patronymic: formData.patronymic,
          birthPlace: formData.birthPlace,
          gender: formData.gender,
          birthDate: formData.birthDate,
          passportNumber: formData.passportNumber,
          issuedBy: formData.issuedBy,
          departamentCode: formData.departamentCode,
          issueDate: formData.issueDate,
          registrationAddress: formData.registrationAddress,
          imagesPassport: [String(formData.imagesPassport)],
        }),
      });

      const { data } = await response.json();

      if (response.ok) {
        mutate(EApiUrls.PASSPORT_DATA);
        setIsSuccessUpdate(true);
        setTimeout(() => navigate(URLS.ACCOUNT), 2000);
      } else {
        setErrorMessage(data.message || FAIL_FETCH);
      }
    } catch (error) {
      setErrorMessage(FAIL_FETCH);
    }
  };

  return (
    <PageLayout isBtnBack={true}>
      <div className="w-full bg-white pt-[16px] pb-[88px] px-[16px] rounded-lg justify-center self-start">
        <h2 className="font-medium mb-[32px] text-[24px] text-header text-start">Паспортные данные</h2>

        <div>
          <InputField
            label="Имя"
            name="name"
            value={formData.name || ''}
            onChange={handleInputChange}
            placeholder="Введите имя"
          />

          <InputField
            label="Фамилия"
            name="surname"
            value={formData.surname || ''}
            onChange={handleInputChange}
            placeholder="Введите фамилию"
          />

          <InputField
            label="Отчество"
            name="patronymic"
            value={formData.patronymic || ''}
            onChange={handleInputChange}
            placeholder="Введите отчество"
          />

          <InputField
            label="Место рождения"
            name="birthPlace"
            value={formData.birthPlace || ''}
            onChange={handleInputChange}
            placeholder="Введите место рождения"
          />

          <InputSelect
            label="Пол"
            name="gender"
            value={formData.gender || ''}
            onChange={handleInputChange}
            options={genders}
          />

          <InputField
            label="Дата рождения"
            type={EInputType.DATE}
            name="birthDate"
            value={formData.birthDate || ''}
            onChange={handleInputChange}
          />

          <InputField
            label="Номер паспорта"
            name="passportNumber"
            value={formData.passportNumber || ''}
            onChange={handleInputChange}
            placeholder="Введите номер паспорта"
          />

          <InputField
            label="Кем выдан"
            name="issuedBy"
            value={formData.issuedBy || ''}
            onChange={handleInputChange}
            placeholder="Введите кем выдан"
          />

          <InputField
            label="Код подразделения"
            name="departamentCode"
            value={formData.departamentCode || ''}
            onChange={handleInputChange}
            placeholder="Введите код подразделения"
          />

          <InputField
            label="Дата выдачи"
            type={EInputType.DATE}
            name="issueDate"
            value={formData.issueDate || ''}
            onChange={handleInputChange}
          />

          <InputField
            label="Адрес регистрации"
            name="registrationAddress"
            value={formData.registrationAddress || ''}
            onChange={handleInputChange}
            placeholder="Введите адрес регистрации"
          />
        </div>

        <PassportUpload
          uploadedFile={uploadedFile}
          handleDrop={handleDrop}
          handleDragOver={handleDragOver}
          handleFileSelect={handleFileSelect}
          handleRemoveFile={handleRemoveFile}
        />

        {errorMessage && <div className="text-[red] mb-4">{errorMessage}</div>}

        <div className="mt-[40px] w-full flex mb-[24px]">
          <Button
            text="Сохранить изменения"
            nameClass={EButtonClass.DEF}
            isLink={false}
            typeBtn={EButtonType.BUTTON}
            handle={handleSubmit}
          />
        </div>

        {isSuccessUpdate && <SuccesSmallNotification text="Данные успешно обновлены" />}
      </div>
    </PageLayout>
  );
};

export default PassportDetailsPage;
