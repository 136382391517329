import useSWR from 'swr';
import { EApiUrls, fetcher, IApiResponse, IApiResponseReturn, ICatalogCard, OPTIONS, useHandleUnauthorized } from '..';

const useGetCatalog = (): IApiResponseReturn<ICatalogCard[]> => {
  const { data, error } = useSWR<IApiResponse<ICatalogCard[]>>(EApiUrls.CATALOG, fetcher, OPTIONS);

  useHandleUnauthorized(error?.status || null);

  if (error) {
    console.error('Ошибка при загрузке данных каталога: ', error);
  }

  return {
    data: data?.data || null,
    code: data?.code || null,
    message: data?.message || null,
    time: data?.time || null,
    isLoading: !error && !data,
    isError: !!error,
  };
};

export default useGetCatalog;
