import { useEffect, useState } from 'react';
import { getFetchCountries, IApiResponse, ICountry } from '..';

const useGetCountries = () => {
  const [response, setResponse] = useState<IApiResponse<ICountry[]> | null>(null);

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const data = await getFetchCountries();
        setResponse(data);
      } catch (error) {
        console.error('Ошибка при загрузке стран: ', error);
        setResponse(null);
      }
    };

    fetchCountries();
  }, []);

  return response;
};

export default useGetCountries;
