import React from 'react';
import { IIcon } from '..';

const ExchangeIcon: React.FC<IIcon> = ({ color, className }) => {
  return (
    <svg width="62" height="62" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2188_3583)">
        <path
          d="M60.9666 12.4001H57.8666C57.4003 12.4001 56.9922 12.7114 56.8694 13.1622L53.9774 23.7668H15.4999C14.929 23.7668 14.4666 24.2292 14.4666 24.8001V47.5334C14.4666 48.1043 14.929 48.5667 15.4999 48.5667H47.2788L46.1267 53.7502L45.6707 55.8001H18.5999C19.7392 55.8001 20.6666 56.7275 20.6666 57.8668H44.4333C44.4333 56.7275 45.3607 55.8001 46.4999 55.8001C46.9482 55.8001 47.3628 55.9435 47.7025 56.1876L48.1688 54.086L49.423 48.4453C50.6824 48.0824 51.6162 46.9418 51.6653 45.5791L55.7741 25.0378L58.6571 14.4668H60.9679C61.5388 14.4668 62.0012 14.0043 62.0012 13.4334C62.0012 12.8625 61.5388 12.4001 60.9679 12.4001H60.9666ZM52.4726 31.0001H44.4333V25.8334H53.5059L52.4726 31.0001ZM51.0259 38.2334H44.4333V33.0668H52.0593L51.0259 38.2334ZM36.1666 38.2334V33.0668H42.3666V38.2334H36.1666ZM42.3666 40.3001V46.5001H36.1666V40.3001H42.3666ZM16.5333 33.0668H34.0999V38.2334H16.5333V33.0668ZM36.1666 31.0001V25.8334H42.3666V31.0001H36.1666ZM34.0999 25.8334V31.0001H16.5333V25.8334H34.0999ZM16.5333 40.3001H34.0999V46.5001H16.5333V40.3001ZM49.5999 45.4668C49.5999 46.0364 49.1362 46.5001 48.5666 46.5001H44.4333V40.3001H50.6126L49.6193 45.264C49.6064 45.3311 49.5987 45.3983 49.5987 45.4668H49.5999Z"
          fill="#B3B3B3"
        />
        <path
          d="M25.8334 1.03326H5.16672C2.88394 1.03326 1.03339 2.88382 1.03339 5.1666V46.4999C1.03339 48.7827 2.88394 50.6333 5.16672 50.6333H25.8334C28.1162 50.6333 29.9667 48.7827 29.9667 46.4999V5.1666C29.9667 2.88382 28.1162 1.03326 25.8334 1.03326Z"
          fill="white"
        />
        <path
          d="M25.8333 51.6667H5.16667C2.31725 51.6667 0 49.3494 0 46.5V5.16667C0 2.31725 2.31725 0 5.16667 0H25.8333C28.6827 0 31 2.31725 31 5.16667V46.5C31 49.3494 28.6827 51.6667 25.8333 51.6667ZM5.16667 2.06667C3.45779 2.06667 2.06667 3.45779 2.06667 5.16667V46.5C2.06667 48.2089 3.45779 49.6 5.16667 49.6H25.8333C27.5422 49.6 28.9333 48.2089 28.9333 46.5V5.16667C28.9333 3.45779 27.5422 2.06667 25.8333 2.06667H5.16667Z"
          fill="#4B4B4B"
        />
        <path d="M19.6333 35.1334H11.3666V38.2334H19.6333V35.1334Z" fill="#B3B3B3" />
        <path d="M19.6333 17.5667H11.3666V20.6667H19.6333V17.5667Z" fill="#B3B3B3" />
        <path d="M19.6333 26.8666H11.3666V28.9333H19.6333V26.8666Z" fill="#B3B3B3" />
        <path d="M19.6333 22.7333H11.3666V24.7999H19.6333V22.7333Z" fill="#B3B3B3" />
        <path d="M19.6333 31H11.3666V33.0667H19.6333V31Z" fill="#B3B3B3" />
        <path d="M19.6333 13.4333H11.3666V15.5H19.6333V13.4333Z" fill="#B3B3B3" />
        <path
          d="M22.1779 55.7999C21.4623 54.5651 20.1267 53.7333 18.5999 53.7333C16.3202 53.7333 14.4666 55.5868 14.4666 57.8666C14.4666 60.1464 16.3202 61.9999 18.5999 61.9999C20.8797 61.9999 22.7333 60.1464 22.7333 57.8666C22.7333 57.1136 22.5305 56.4083 22.1779 55.7999ZM18.5999 59.9333C17.4607 59.9333 16.5333 59.0059 16.5333 57.8666C16.5333 56.7274 17.4323 55.8284 18.547 55.8012C18.5638 55.7999 18.5819 55.7999 18.5999 55.7999C19.7392 55.7999 20.6666 56.7274 20.6666 57.8666C20.6666 59.0059 19.7392 59.9333 18.5999 59.9333Z"
          fill="#B3B3B3"
        />
        <path
          d="M48.1688 54.0859C47.6586 53.8586 47.0928 53.7333 46.5 53.7333C46.3747 53.7333 46.2494 53.7384 46.1267 53.7501C44.7575 53.8728 43.5782 54.6659 42.9221 55.7999C42.5694 56.4083 42.3666 57.1136 42.3666 57.8666C42.3666 60.1464 44.2202 61.9999 46.5 61.9999C48.7798 61.9999 50.6333 60.1464 50.6333 57.8666C50.6333 56.181 49.6193 54.7279 48.1688 54.0859ZM46.5 59.9333C45.3607 59.9333 44.4333 59.0059 44.4333 57.8666C44.4333 56.7274 45.3607 55.7999 46.5 55.7999C46.9482 55.7999 47.3628 55.9433 47.7025 56.1874C48.2256 56.562 48.5666 57.1756 48.5666 57.8666C48.5666 59.0059 47.6392 59.9333 46.5 59.9333Z"
          fill="#B3B3B3"
        />
        <path
          d="M18.6492 10.3334H20.6798C22.3918 10.3334 23.7798 11.7213 23.7798 13.4334V15.5"
          stroke="#7BB91A"
          strokeWidth="1.55"
          strokeLinecap="round"
        />
        <path
          d="M12.3818 10.3333H10.3513C8.63923 10.3333 7.25131 11.7212 7.25131 13.4333V15.4999"
          stroke="#7BB91A"
          strokeWidth="1.55"
          strokeLinecap="round"
        />
        <path
          d="M12.3818 41.3333H10.3513C8.63923 41.3333 7.25131 39.9454 7.25131 38.2333V36.1667"
          stroke="#7BB91A"
          strokeWidth="1.55"
          strokeLinecap="round"
        />
        <path
          d="M18.6179 41.3333H20.6484C22.3605 41.3333 23.7484 39.9454 23.7484 38.2333V36.1667"
          stroke="#7BB91A"
          strokeWidth="1.55"
          strokeLinecap="round"
        />
        <path d="M10.3334 2.06665L11.625 4.41152H19.375L20.6667 2.06665H10.3334Z" fill="#4B4B4B" />
      </g>
      <defs>
        <clipPath id="clip0_2188_3583">
          <rect width="62" height="62" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ExchangeIcon;
