import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { URLS } from '../../utils';
import { AccountIcon, CatalogIcon, HomeIcon, ScannerIcon } from '../components';

interface MenuItem {
  url: string;
  text: string;
  icon: React.FC<{ color?: string }>;
}

const Footer: React.FC = () => {
  const location = useLocation();
  const [pathnameLocation, setPathnameLocation] = useState<string>('');

  const MENU: MenuItem[] = [
    {
      url: URLS.HOME,
      text: 'Главная',
      icon: HomeIcon,
    },
    {
      url: URLS.SCANNER,
      text: 'Сканер',
      icon: ScannerIcon,
    },
    {
      url: URLS.CATALOG,
      text: 'Каталог',
      icon: CatalogIcon,
    },
    {
      url: URLS.ACCOUNT,
      text: 'Аккаунт',
      icon: AccountIcon,
    },
  ];

  useEffect(() => {
    setPathnameLocation(location.pathname);
  }, [location]);

  const validUrls = [URLS.CATALOG, URLS.PRODUCT, URLS.SCANNER, URLS.ACCOUNT, URLS.HOME];

  const isPathValid = (path: string, validPaths: string[]) => validPaths.some((url) => path.startsWith(url));

  const getIconColor = (url: string) => {
    if (url === URLS.HOME) {
      return pathnameLocation === url ? '#7BB91A' : '#B3B3B3';
    }
    if (url === URLS.CATALOG) {
      return pathnameLocation.startsWith(url) || pathnameLocation.includes(URLS.PRODUCT) ? '#7BB91A' : '#B3B3B3';
    }
    return pathnameLocation.startsWith(url) ? '#7BB91A' : '#B3B3B3';
  };

  const getTxtColor = (url: string) => {
    if (url === URLS.HOME) {
      return pathnameLocation === url ? 'text-green' : 'text-light';
    }
    if (url === URLS.CATALOG) {
      return pathnameLocation.startsWith(url) || pathnameLocation.includes(URLS.PRODUCT) ? 'text-green' : 'text-light';
    }
    return pathnameLocation.startsWith(url) ? 'text-green' : 'text-light';
  };

  if (pathnameLocation && isPathValid(pathnameLocation, validUrls)) {
    return (
      <footer className="w-full fixed bottom-0 left-0 bg-white border border-[#F8F8F8] z-10">
        <ul className="grid grid-cols-4 gap-4 pr-4 pl-4 justify-items-center max-w-[375px] mx-auto">
          {MENU.map((item) => {
            const IconComponent = item.icon;
            return (
              <li key={item.url}>
                <Link to={item.url} className="flex flex-col justify-center items-center h-[88px]">
                  <IconComponent color={getIconColor(item.url)} />
                  <span className={`text-[12px] font-[500] leading-[24px] ${getTxtColor(item.url)}`}>{item.text}</span>
                </Link>
              </li>
            );
          })}
        </ul>
      </footer>
    );
  }

  return null;
};

export default Footer;
