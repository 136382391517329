// account
export { default as AccountLayout } from './account/AccountLayout';
export { default as CardDetailsPage } from './account/CardDetailsPage';
export { default as ChangePasswordPage } from './account/ChangePasswordPage';
export { default as ContactsPage } from './account/ContactsPage';
export { default as DocumentFlowPage } from './account/DocumentFlowPage';
export { default as HistoryPage } from './account/HistoryPage';
export { default as PersonalInfoPage } from './account/PersonalInfoPage';
export { default as PointsPage } from './account/PointsPage';
export { default as ProgramRulesPage } from './account/ProgramRulesPage';
export { default as SnilsInnPage } from './account/SnilsInnPage';
export { default as PassportDetailsPage } from './account/PassportDetailsPage';

//auth
export { default as LoginPage } from './auth/LoginPage';
export { default as LoginPhonePage } from './auth/LoginPhonePage';
export { default as RegisterPage } from './auth/RegisterPage';
export { default as ResetPasswordPage } from './auth/ResetPasswordPage';

// catalog
export { default as CatalogPage } from './catalog/CatalogPage';
export { default as CertificatesCategory } from './catalog/CertificatesCategory';
export { default as PrizesCategory } from './catalog/PrizesCategory';

// home
export { default as HomePage } from './home/HomePage';

// product
export { default as InfoProductCard } from './product/InfoProductCard';
export { default as MenuOrder } from './product/MenuOrder';
export { default as ProductPage } from './product/ProductPage';
export { default as ProductContent } from './product/ProductContent';
export type { default as IProductCard } from './product/ProductPage';

// scanner
export { default as ScannerPage } from './scanner/ScannerPage';

// start
export { default as StartPage } from './start/StartPage';
