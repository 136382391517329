import React from 'react';
import { BtnBack, Button, EButtonClass, EButtonType } from '.';
import { PUBLIC_URL, URLS } from '../../utils';

const EmptyTransactions: React.FC = () => {
  return (
    <div className="h-[calc(100% - 90px - 64px)] w-full bg-white fixed top-[64px] bottom-[90px] left-0 z-2">
      <div className="max-w-[375px] flex flex-col items-center h-full mx-auto">
        <BtnBack />
        <div className="flex flex-col items-center justify-center gap-y-[24px] max-w-[280px] mx-auto pt-[80px]">
          <div className="w-[150px] h-[150px]">
            <img width={150} height={150} draggable="false" src={`${PUBLIC_URL}/image/History.png`} alt="" />
          </div>
          <div className="text-main text-[14px] leading-[18.9px] text-center">История транзакций пуста</div>
        </div>
        <div className="w-full mt-auto px-[16px] mb-[24px]">
          <Button
            text="Обменять баллы на призы"
            nameClass={EButtonClass.DEF}
            isLink={true}
            linkUrl={URLS.CATALOG}
            typeBtn={EButtonType.BUTTON}
          />
        </div>
      </div>
    </div>
  );
};

export default EmptyTransactions;
