import React from 'react';
import { DocumentFlowLink } from '../../components';
import { URLS } from '../../../utils';
import { PageLayout } from '../../layout';

const DocumentFlowPage: React.FC = () => {
  return (
    <PageLayout isBtnBack={true}>
      <div className="relative w-full bg-white pt-[16px] pb-[88px] px-[16px] rounded-lg flex-1 justify-center self-start">
        <h2 className="font-medium mb-[20px] text-[24px] text-header text-start">Документооборот</h2>
        <p className="text-main font-normal	text-sm mb-8 leading-[19px]">
          Для того, чтобы обменять баллы на призы вам необходимо заполнить все поля в личном кабинете и прикрепить
          необходимые документы
        </p>
        <div>
          <ul className="list-none p-0">
            <li className="mb-[12px]">
              <DocumentFlowLink name="Паспортные данные" link={URLS.ACCOUNT_PASSPORT_DETAILS} />
            </li>
            <li className="mb-[12px]">
              <DocumentFlowLink name="СНИЛС и ИНН" link={URLS.ACCOUNT_SNILS_INN} />
            </li>
            <li className="mb-[12px]">
              <DocumentFlowLink name="Реквизиты банковской карты" link={URLS.ACCOUNT_CARD_DETAILS} />
            </li>
          </ul>
        </div>
      </div>
    </PageLayout>
  );
};

export default DocumentFlowPage;
