import React from 'react';
import { useNavigate } from 'react-router-dom';
import { EButtonType } from '..';

const BtnBack: React.FC = () => {
  const navigate = useNavigate();
  const goBack = () => navigate(-1);

  return (
    <button
      onClick={goBack}
      type={EButtonType.BUTTON}
      className="flex gap-[2px] items-center self-start group mt-6 ml-4 text-start"
    >
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M12.1431 14.2863L7.85742 10.0006L12.1431 5.71484"
          stroke="#656565"
          strokeWidth="1.3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      <p className="text-main text-base font-normal">Назад</p>
    </button>
  );
};

export default BtnBack;
