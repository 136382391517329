import React from 'react';
import { IIcon } from '..';

const PointsIcon: React.FC<IIcon> = ({ color = '#4B4B4B' }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.2866 16.2866C19.551 15.5928 22 12.6935 22 9.22222C22 5.2335 18.7665 2 14.7778 2C11.3065 2 8.40722 4.44896 7.7134 7.7134M16.2866 16.2866C16.39 15.8 16.4444 15.2952 16.4444 14.7778C16.4444 10.7891 13.2109 7.55556 9.22222 7.55556C8.70477 7.55556 8.20002 7.60997 7.7134 7.7134M16.2866 16.2866C15.5928 19.551 12.6935 22 9.22222 22C5.2335 22 2 18.7665 2 14.7778C2 11.3065 4.44896 8.40722 7.7134 7.7134M8.82939 12.3928L6.83728 14.3849C6.62032 14.6019 6.62032 14.9537 6.83728 15.1706L8.82939 17.1627C9.04634 17.3797 9.3981 17.3797 9.61506 17.1627L11.6072 15.1706C11.8241 14.9537 11.8241 14.6019 11.6072 14.3849L9.61506 12.3928C9.3981 12.1759 9.04634 12.1759 8.82939 12.3928Z"
        stroke={color}
        strokeWidth="1.3"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default PointsIcon;
