import React from 'react';
import { EInputType } from '..';

interface ICheckboxInput {
  id: string;
  label: string;
  checked?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const CheckboxInput: React.FC<ICheckboxInput> = ({ id, label, checked, onChange }) => {
  return (
    <div className="flex gap-[2.5px] items-center custom-checkbox">
      <input
        type={EInputType.CHECKBOX}
        id={id}
        {...(checked !== undefined ? { checked } : {})}
        {...(onChange ? { onChange } : {})}
      />
      <label htmlFor={id} className="text-main text-base font-normal c-p">
        {label}
      </label>
    </div>
  );
};

export default CheckboxInput;
