export enum EButtonClass {
  DEF = 'default',
  SEC = 'sec',
  TRE = 'tre',
}

export enum EButtonType {
  BUTTON = 'button',
  SUBMIT = 'submit',
  RESET = 'reset',
}
