import React, { useRef, useState } from 'react';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { EInputType } from '..';

interface IInputPassword {
  label: string;
  placeholder: string;
  value?: string;
  name: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const InputPassword: React.FC<IInputPassword> = (props) => {
  const [showPassword, setShowPassword] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const togglePasswordVisibility = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setShowPassword((prev) => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
      return !prev;
    });
  };

  return (
    <div className="mt-5">
      <label className="text-sm font-normal">{props.label}*</label>
      <div className="items-center relative">
        <input
          type={showPassword ? EInputType.TEXT : EInputType.PASSWORD}
          name={props.name}
          value={props.value}
          onChange={props.onChange}
          placeholder={props.placeholder}
          ref={inputRef}
          className="focus:outline-none focus:ring-1 focus:ring-green w-full pt-[13px] pb-[14px] px-3 pr-12 border rounded-xl leading-5"
        />
        {showPassword ? (
          <VisibilityOffOutlinedIcon
            color="disabled"
            className="absolute right-3 top-1/2 transform -translate-y-1/2 h-6 w-6 cursor-pointer"
            onMouseDown={togglePasswordVisibility}
          />
        ) : (
          <VisibilityOutlinedIcon
            color="disabled"
            className="absolute right-3 top-1/2 transform -translate-y-1/2 h-6 w-6 cursor-pointer"
            onMouseDown={togglePasswordVisibility}
          />
        )}
      </div>
    </div>
  );
};

export default InputPassword;
