import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowRightIcon } from '..';

interface IDocumentFlowLink {
  link: string;
  name: string;
}

const DocumentFlowLink: React.FC<IDocumentFlowLink> = ({ link, name }) => {
  return (
    <Link className="flex p-[12px] rounded-xl bg-light_grey items-center justify-between" to={link}>
      <div className="flex items-center">
        <p className="py-[9px]">{name}</p>
      </div>
      <div className="rounded-lg items-end">
        <ArrowRightIcon className="h-[14px] w-[14px]" />
      </div>
    </Link>
  );
};

export default DocumentFlowLink;
