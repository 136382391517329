import React from 'react';
import { ICatalogCard } from '../../../api';

const InfoProductCard: React.FC<ICatalogCard> = (props) => {
  const { price, image, name, text } = props;

  return (
    <>
      <div className="bg-red_light rounded-[12px] pt-[9px] pr-[12px] pl-[12px] pb-[18px] h-[210px] flex flex-col justify-between mb-[20px]">
        <div className="w-full h-[120px]">
          <img className="object-cover w-full h-full" src={image} alt={name} width={260} height={120} />
        </div>
        <div>
          <div className="text-white text-[16px] leading-[17.6px] font-[600] mb-[6px]">подарочная карта</div>
          <div className="text-white text-[24px] leading-[26.4px] font-[600]">{price} ₽</div>
        </div>
      </div>
      <h2 className="text-[24px] leading-[28.8px] mb-[12px] font-[500] text-header">{name}</h2>
      <div className="text-[18px] leading-[21.6px] font-[500] text-header mb-[16px]">{price} баллов</div>
      <div className="text-[14px] leading-[18.9px] text-main mb-[16px]" dangerouslySetInnerHTML={{ __html: text }} />
    </>
  );
};

export default InfoProductCard;
