import React from 'react';
import { EInputType } from '..';

interface IInputFieldProps {
  label: string;
  name: string;
  value: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  required?: boolean;
  type?: string;
  readonly?: boolean;
}

const InputField: React.FC<IInputFieldProps> = ({
  label,
  name,
  value,
  onChange,
  placeholder,
  readonly = false,
  required = true,
  type = EInputType.TEXT,
}) => {
  return (
    <div className="w-full mb-4">
      <p className="text-sm font-normal">
        {label}
        {required ? '*' : ''}
      </p>
      <input
        type={type}
        name={name}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        required={required}
        readOnly={readonly}
        className={`w-full py-[14px] px-[12px] border rounded-xl leading-5 ${
          readonly
            ? 'bg-gray-200 focus:outline-none focus:transparent'
            : 'focus:outline-none focus:ring-1 focus:ring-green'
        }`}
      />
    </div>
  );
};

export default InputField;
