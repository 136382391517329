import React, { useEffect, useState } from 'react';
import { QrReader } from '@blackbox-vision/react-qr-reader';
import { BtnBack, Button, EButtonClass, EButtonType, EInputType, SuccessNotification } from '../../components';
import { URLS } from '../../../utils';
import { EApiUrls } from '../../../api';

const FAIL_FETCH: string = 'Произошла ошибка при активации QR-кода. Пожалуйста, попробуйте еще раз';

const ScannerPage: React.FC = () => {
  const [isManualInput, setIsManualInput] = useState(false);
  const [value, setValue] = useState<string>('');
  const [isOpenSuccessNotification, setIsOpenSuccessNotification] = useState<boolean>(false);
  const [isOpenNegativeNotification, setIsOpenNegativeNotification] = useState<boolean>(false);
  const [isScanned, setIsScanned] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  useEffect(() => {
    document.body.classList.add('overflow-hidden');
    return () => {
      document.body.classList.remove('overflow-hidden');
    };
  }, []);

  const handleScan = (result: any) => {
    if (result?.text && !isScanned) {
      setValue(result.text);
      setIsManualInput(true);
      setIsScanned(true);
    }
  };

  const handleManualInput = () => {
    setIsManualInput(true);
  };

  const handleBackToScan = () => {
    setIsManualInput(false);
    setValue('');
    setIsScanned(false);
    setErrorMessage(null);
  };

  const handleClear = () => {
    handleBackToScan();
  };

  const handleActivate = async () => {
    setErrorMessage(null);
    try {
      const payload = { code: value };

      const response = await fetch(EApiUrls.ACTIVATE_PROMOCODE, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      const { data } = await response.json();

      if (response.ok) {
        setIsOpenSuccessNotification(true);
        setValue('');
      } else {
        setErrorMessage(data.message || FAIL_FETCH);
        setIsOpenNegativeNotification(true);
      }
    } catch (error) {
      setErrorMessage(FAIL_FETCH);
      setIsOpenNegativeNotification(true);
    }
  };

  return (
    <>
      {isManualInput ? (
        <div className="h-full pt-[64px] flex flex-col justify-start items-center bg-white w-full">
          <BtnBack />
          <div className="w-full bg-white p-[16px] rounded-lg flex-1 flex-col justify-center">
            <h2 className="font-medium mb-[32px] text-[26px] text-header">Активация QR-кода</h2>
            <div className="text-main text-[14px] leading-[18.9px] mb-[32px]">
              Введите код с купона из упаковки продукции и нажмите кнопку активировать
            </div>
            <p className="text-[14px] leading-[18.9px] mb-[4px] font-normal">QR-код</p>
            <div className="relative w-full mb-4">
              <input
                value={value}
                onChange={(e) => setValue(e.target.value)}
                type={EInputType.TEXT}
                placeholder="Введите QR-код"
                className="focus:outline-none focus:ring-1 focus:ring-green w-full pt-[13px] pb-[14px] px-[12px] border rounded-[12px] leading-[20.8px]"
              />
              <button
                onClick={handleClear}
                type={EButtonType.BUTTON}
                className="absolute right-2 top-1/2 transform -translate-y-1/2 p-2 bg-gray-200 rounded-full focus:outline-none"
              >
                ⭯
              </button>
            </div>
            {errorMessage && <div className="text-[red] mb-4">{errorMessage}</div>}
          </div>

          <div className="w-full pt-[32px] pb-[112px] pl-[16px] pr-[16px]">
            <Button
              text="Активировать"
              nameClass={EButtonClass.DEF}
              isLink={false}
              typeBtn={EButtonType.BUTTON}
              isDisabled={!value}
              handle={handleActivate}
            />
          </div>

          {isOpenSuccessNotification && (
            <SuccessNotification
              title={'QR-код успешно активирован, баллы зачислены на ваш счет'}
              setIsOpenNotification={setIsOpenSuccessNotification}
              button={<Button text="Хорошо" nameClass={EButtonClass.DEF} isLink={true} linkUrl={URLS.HOME} />}
              isSuccess={true}
            />
          )}

          {isOpenNegativeNotification && (
            <SuccessNotification
              title={
                'QR-код не найден. <br> Попробуйте еще раз или обратитесь <br> в <a href="#" class="text-green">службу поддержки</a>'
              }
              setIsOpenNotification={setIsOpenNegativeNotification}
              button={
                <Button
                  text="Сканировать QR-код"
                  nameClass={EButtonClass.DEF}
                  isLink={false}
                  handle={() => {
                    setIsOpenNegativeNotification(false);
                  }}
                />
              }
              isSuccess={false}
            />
          )}
        </div>
      ) : (
        <div className="scanner-container">
          {!isScanned && (
            <QrReader onResult={handleScan} constraints={{ facingMode: 'environment' }} className="qr-reader" />
          )}
          <div className="absolute top-1/3 left-1/2 w-52 h-52 transform -translate-x-1/2 -translate-y-1/2">
            <div className="absolute w-12 h-12 border-t-4 border-l-4 border-white rounded-tl-lg top-0 left-0"></div>
            <div className="absolute w-12 h-12 border-t-4 border-r-4 border-white rounded-tr-lg top-0 right-0"></div>
            <div className="absolute w-12 h-12 border-b-4 border-l-4 border-white rounded-bl-lg bottom-0 left-0"></div>
            <div className="absolute w-12 h-12 border-b-4 border-r-4 border-white rounded-br-lg bottom-0 right-0"></div>
          </div>
          <div className="absolute top-[calc(50%+50px)] w-[90%] mt-2 text-white text-lg text-center">
            Направьте камеру на QR-код
            <br />
            для сканирования
          </div>
          <div className="absolute top-[calc(50%+120px)] mt-2">
            <Button
              text="Ввести код"
              nameClass={EButtonClass.TRE}
              isLink={false}
              typeBtn={EButtonType.BUTTON}
              handle={handleManualInput}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default ScannerPage;
