// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-checkbox label {
  display: flex;
  align-items: flex-start;
  cursor: pointer;
  column-gap: 8px;
  position: relative;
}

.custom-checkbox label::before {
  content: '';
  width: 20px;
  height: 20px;
  border: 1px solid #dedede;
  border-radius: 5px;
  background-color: #ffffff;
  flex-shrink: 0;
}

.custom-checkbox input[type='checkbox'] {
  display: none;
}

.custom-checkbox input:checked + label::before {
  border-color: #7bb91a;
  background-color: #7bb91a;
}

.custom-checkbox input:checked + label::after {
  content: '';
  position: absolute;
  top: 3px;
  left: 7px;
  width: 6px;
  height: 11px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
`, "",{"version":3,"sources":["webpack://./src/styles/CustomCheckbox.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,eAAe;EACf,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,WAAW;EACX,YAAY;EACZ,yBAAyB;EACzB,kBAAkB;EAClB,yBAAyB;EACzB,cAAc;AAChB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,qBAAqB;EACrB,yBAAyB;AAC3B;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,UAAU;EACV,YAAY;EACZ,mBAAmB;EACnB,yBAAyB;EACzB,wBAAwB;AAC1B","sourcesContent":[".custom-checkbox label {\n  display: flex;\n  align-items: flex-start;\n  cursor: pointer;\n  column-gap: 8px;\n  position: relative;\n}\n\n.custom-checkbox label::before {\n  content: '';\n  width: 20px;\n  height: 20px;\n  border: 1px solid #dedede;\n  border-radius: 5px;\n  background-color: #ffffff;\n  flex-shrink: 0;\n}\n\n.custom-checkbox input[type='checkbox'] {\n  display: none;\n}\n\n.custom-checkbox input:checked + label::before {\n  border-color: #7bb91a;\n  background-color: #7bb91a;\n}\n\n.custom-checkbox input:checked + label::after {\n  content: '';\n  position: absolute;\n  top: 3px;\n  left: 7px;\n  width: 6px;\n  height: 11px;\n  border: solid white;\n  border-width: 0 2px 2px 0;\n  transform: rotate(45deg);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
