import useSWR from 'swr';
import { EApiUrls, IApiResponse, IApiResponseReturn, IBankCard, OPTIONS } from '../interfaces';
import { fetcher } from '../fetcher';
import { useHandleUnauthorized } from '..';

const useGetBankCard = (): IApiResponseReturn<IBankCard> => {
  const { data, error } = useSWR<IApiResponse<IBankCard>>(EApiUrls.BANK_CARD, fetcher, OPTIONS);

  useHandleUnauthorized(data?.code || null);

  if (error) {
    console.error('Ошибка при загрузке банковских данных: ', error);
  }

  return {
    data: data?.data || null,
    code: data?.code || null,
    message: data?.message || null,
    time: data?.time || null,
    isLoading: !error && !data,
    isError: !!error,
  };
};

export default useGetBankCard;
