import React, { useEffect, useState } from 'react';
import rulesData from '../../../staticData/rules.json';
import { PageLayout } from '../../layout';

interface Rule {
  id: number;
  paragraph: number;
  text: string;
}

const ProgramRulesPage: React.FC = () => {
  const [rules, setRules] = useState<Rule[]>([]);

  useEffect(() => {
    setRules(rulesData);
  }, []);

  return (
    <PageLayout isBtnBack={true}>
      <div className="w-full bg-white pt-[26px] px-[16px] pb-[112px] rounded-lg flex-1 justify-center self-start">
        <h2 className="font-medium mb-[32px] text-[24px] text-header text-start">Правила программы</h2>
        <div>
          {rules.length > 0 ? (
            rules.map((rule) => (
              <div key={rule.id} className="mb-[8px] text-sm text-gray-700 flex leading-[19px]">
                <p className="pr-[4px] w-[24px] flex-shrink-0">{rule.paragraph}.</p>
                <p>{rule.text}</p>
              </div>
            ))
          ) : (
            <p>Loading rules...</p>
          )}
        </div>
      </div>
    </PageLayout>
  );
};

export default ProgramRulesPage;
