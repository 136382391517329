import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { getCookie, URLS } from '../../utils';
import { CoinsIcon, LogoIcon } from '../components';
import { useGetPoints } from '../../api';
import { SESSION_ID_COOKIE } from '../../utils/consts';

export const Header: React.FC = () => {
  const location = useLocation();
  const hiddenPaths: string[] = [URLS.START, URLS.LOGIN, URLS.SIGN_PHONE, URLS.RESET_PASS, URLS.REGISTER];
  const hideDesign2 = hiddenPaths.includes(location.pathname);

  const { data: pointsData } = useGetPoints();
  const isAuthenticated = getCookie(SESSION_ID_COOKIE);

  const linkTo = isAuthenticated ? URLS.HOME : URLS.START;

  return (
    <div className="w-full fixed top-0 left-0 bg-white border border-[#F8F8F8] z-100">
      <div className="max-w-[375px] mx-auto flex justify-between items-center p-[16px]">
        <div className="flex items-center">
          <Link to={linkTo}>
            <LogoIcon className="h-8 w-8" />
          </Link>
        </div>
        {!hideDesign2 && isAuthenticated && (
          <div className="bg-light_grey py-1 px-[10px] rounded-lg flex items-center">
            <CoinsIcon color="#4B4B4B" className="h-6 w-6" />
            <p className="text-base font-normal text-main ml-2">{pointsData?.points || 0}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;
