import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PUBLIC_URL, URLS } from '../../../utils';
import {
  AccountIcon,
  AccountLink,
  AttentionIcon,
  CoinsIcon,
  ConfirmAction,
  DocumentIcon,
  ExitIcon,
  HelpContactIcon,
  KeyIcon,
  PointsIcon,
  TimeIcon,
} from '../../components';
import { EApiUrls, useGetPoints, useGetUserInfo } from '../../../api';
import { SESSION_ID_COOKIE } from '../../../utils/consts';

const FAIL_FETCH: string = 'Logout failed';

const AccountLayout: React.FC = () => {
  const [showLogoutModal, setShowLogoutModal] = useState(false);

  const { data: pointsData } = useGetPoints();
  const { data: personalInfoData } = useGetUserInfo();

  const navigate = useNavigate();

  const handleConfirmLogout = async () => {
    try {
      const response = await fetch(EApiUrls.LOGOUT, {
        method: 'POST',
        credentials: 'include',
      });

      const { data } = await response.json();

      if (response.ok) {
        document.cookie = `${SESSION_ID_COOKIE}=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT`;
        setShowLogoutModal(false);
        navigate(URLS.START);
      } else {
        throw new Error(data.message || FAIL_FETCH);
      }
    } catch (error) {
      console.error(FAIL_FETCH, error);
    }
  };

  return (
    <div className="h-full flex flex-col justify-start items-center bg-white w-full">
      <div className="w-full bg-white pt-[64px] px-[16px] pb-[88px] flex flex-1 flex-col justify-center flex-grow">
        <div className="pt-[32px]">
          <div className="flex items-center">
            {personalInfoData?.avatar ? (
              <div className="w-16 h-16 rounded-full">
                <img src={personalInfoData.avatar} alt="" className="w-full h-full object-cover" />
              </div>
            ) : (
              <div className="bg-light_grey rounded-full w-16 h-16 flex items-center justify-center mr-4 text-green font-bold text-lg">
                {personalInfoData?.name?.slice(0, 1)}
                {personalInfoData?.surname?.slice(0, 1)}
              </div>
            )}
            <div className="text-left">
              <p className="font-medium">
                {personalInfoData?.name} {personalInfoData?.surname}
              </p>
              <p className="text-secondary font-normal text-sm">{personalInfoData?.email}</p>
            </div>
          </div>
          <div className="flex mt-[20px] justify-between bg-gradient-to-r from-[#95C943] to-[#7BB91A] rounded-[12px] p-[16px] h-[146px]">
            <div className="flex flex-col justify-between max-w-[130px]">
              <p className="text-[14px] leading-[18.9px] text-white font-medium mb-[4px]">Актуальных баллов на счёту</p>
              <div className="text-white text-[32px] leading-[40px] mb-[12px]">{pointsData?.points || 0}</div>
              <div className="text-white flex bg-green_light rounded-[8px] text-[12px] leading-[15.6px] items-center py-[4px] px-[8px]">
                <CoinsIcon className="h-[14px] w-[14px]" />
                <p>1 балл = 1 рубль</p>
              </div>
            </div>
            <div>
              <img
                src={`${PUBLIC_URL}/image/CoinsImg.png`}
                alt="Onboarding Img"
                className="justify-center"
                width={145}
                height={110}
                draggable="false"
              />
            </div>
          </div>
        </div>
        <div className="mt-[32px] mb-[40px]">
          <ul className="list-none p-0">
            <li className="mt-2">
              <AccountLink label="Персональные данные" url={URLS.ACCOUNT_PERSONAL_INFO} icon={<AccountIcon />} />
            </li>

            <li className="mt-2">
              <AccountLink label="Документооборот" url={URLS.ACCOUNT_DOCUMENT_FLOW} icon={<DocumentIcon />} />
            </li>

            <li className="mt-2">
              <AccountLink label="Баллы" url={URLS.ACCOUNT_POINTS} icon={<PointsIcon />} />
            </li>

            <li className="mt-2">
              <AccountLink label="История транзакций" url={URLS.ACCOUNT_HISTORY} icon={<TimeIcon />} />
            </li>

            <li className="mt-2">
              <AccountLink label="Правила программы" url={URLS.ACCOUNT_RULES} icon={<AttentionIcon />} />
            </li>

            <li className="mt-2">
              <AccountLink label="Смена пароля" url={URLS.ACCOUNT_SETTINGS} icon={<KeyIcon />} />
            </li>

            <li className="mt-2">
              <AccountLink label="Помощь и контакты" url={URLS.ACCOUNT_CONTACTS} icon={<HelpContactIcon />} />
            </li>

            <li className="mt-2">
              <button
                className="flex p-[12px] rounded-xl bg-light_grey items-center justify-between w-full"
                onClick={() => setShowLogoutModal(true)}
              >
                <div className="flex items-center">
                  <div className="p-2 rounded-lg bg-white">
                    <ExitIcon className="h-[14px] w-[14px]" />
                  </div>
                  <p className="py-[9px] pl-[12px]">Выход</p>
                </div>
              </button>
            </li>
          </ul>
        </div>
        {showLogoutModal && (
          <ConfirmAction
            title={`Вы действительно хотите выйти из приложения?`}
            btnTextSuccess="Выйти"
            btnTextNegative="Отмена"
            setIsOpenConfirmOrder={setShowLogoutModal}
            handleSuccessBtn={() => {
              setShowLogoutModal(false);
              handleConfirmLogout();
            }}
            handleNegativeBtn={() => setShowLogoutModal(false)}
          />
        )}
      </div>
    </div>
  );
};

export default AccountLayout;
