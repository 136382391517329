import React from 'react';
import { Navigate } from 'react-router-dom';
import { getCookie, URLS } from '../../utils';
import { SESSION_ID_COOKIE } from '../../utils/consts';

interface IProtectedRouteUser {
  children: React.ReactNode;
}

const ProtectedRouteUser: React.FC<IProtectedRouteUser> = ({ children }) => {
  const isAuthenticated = getCookie(SESSION_ID_COOKIE);

  if (isAuthenticated) {
    return <Navigate to={URLS.HOME} />;
  }

  return <>{children}</>;
};

export default ProtectedRouteUser;
