import React from 'react';
import { EButtonType } from '..';

interface ISuccessNotification {
  title: string;
  setIsOpenNotification: (value: boolean) => void;
  button: React.ReactNode;
  isSuccess: boolean;
}

const SuccessNotification: React.FC<ISuccessNotification> = (props) => {
  const { title, setIsOpenNotification, button, isSuccess } = props;

  return (
    <div className="h-[calc(100% - 90px - 64px)] w-full bg-white fixed top-[64px] bottom-[90px] left-0 z-2">
      <div className="max-w-[375px] px-[16px] pt-[24px] pb-[24px] flex flex-col items-center h-full mx-auto">
        <div className="flex justify-end w-full h-[24px]">
          <button type={EButtonType.BUTTON} className="w-[24px] h-[24px]" onClick={() => setIsOpenNotification(false)}>
            <img src="/Icons/close.svg" alt="Close" />
          </button>
        </div>
        <div className="flex flex-col items-center justify-center gap-y-[24px] max-w-[280px] mx-auto pt-[80px]">
          <div className="w-[158px] h-[158px]">
            {isSuccess ? (
              <img src="/Icons/success-notification.svg" alt="Success" />
            ) : (
              <img src="/Icons/nosuccess-notification.svg" alt="No Success" />
            )}
          </div>
          <div
            className="text-main text-[14px] leading-[18.9px] text-center"
            dangerouslySetInnerHTML={{ __html: title }}
          />
        </div>
        <div className="mt-auto w-full">{button}</div>
      </div>
    </div>
  );
};

export default SuccessNotification;
