import React, { useState, useEffect } from 'react';
import { EInputType } from '..';

interface IOption {
  id: number;
  name: string;
}

interface IInputSelect {
  label: string;
  name: string;
  value: string | number | null;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  options?: IOption[] | null;
  required?: boolean;
}

const InputSelect: React.FC<IInputSelect> = ({ label, name, value, onChange, options, required = true }) => {
  const [sortedOptions, setSortedOptions] = useState<IOption[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<IOption | null>(
    options ? options.find((option) => option.id === value) || null : null
  );

  useEffect(() => {
    if (options) {
      const initialSelectedOption = options.find((option) => option.id === value) || null;
      setSelectedOption(initialSelectedOption);
    }
  }, [value, options]);

  useEffect(() => {
    if (options) {
      const sorted = [...options].sort((a, b) => a.name.localeCompare(b.name));
      setSortedOptions(sorted);
    }
  }, [options]);

  const handleToggle = () => {
    if (options) setIsOpen(!isOpen);
  };

  const handleOptionChange = (option: IOption) => {
    setSelectedOption(option);
    setIsOpen(false);

    if (onChange) {
      const event = {
        target: {
          name: name,
          value: option.id,
        },
      } as unknown as React.ChangeEvent<HTMLInputElement>;
      onChange(event);
    }
  };

  return (
    <div className="relative w-full mb-4">
      <label className="text-[14px] leading-[18.9px] text-header font-normal mb-[4px]">
        {label}
        {required ? '*' : ''}
      </label>
      <div
        className={`flex items-center justify-between py-[13px] px-[12px] border rounded-[12px] transition duration-300 ease-in-out ${isOpen ? 'border-green' : 'border-stroke'}`}
        onClick={handleToggle}
      >
        <div className="flex items-center space-x-2 overflow-hidden">
          <span className={`text-[16px] leading-[20.8px] ${selectedOption ? 'text-header' : 'text-txt_sec'} truncate`}>
            {selectedOption ? selectedOption.name : 'Выберите из списка'}
          </span>
        </div>
        {options && (
          <div className="flex items-center transition-transform duration-300 ease-in-out">
            <svg
              width="20"
              height="21"
              viewBox="0 0 20 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className={`transform transition-transform duration-300 ${isOpen ? 'rotate-180' : ''}`}
            >
              <path
                d="M15 8.83337L10.5893 13.2441C10.2638 13.5696 9.73618 13.5696 9.41074 13.2441L5 8.83337"
                stroke="#B3B3B3"
                strokeWidth="1.3"
                strokeLinecap="round"
              />
            </svg>
          </div>
        )}
      </div>
      {isOpen && (
        <div className="absolute mt-[7px] w-full bg-white border border-stroke rounded-[12px] z-10 shadow-[0px_1px_2px_0px_#1018280A]">
          <div className="max-h-[170px] overflow-y-auto">
            {sortedOptions.map((option) => (
              <div
                className="flex items-center py-[11px] px-[16px]"
                key={option.id}
                onClick={() => handleOptionChange(option)}
              >
                <input
                  id={`option-${option.id}`}
                  type={EInputType.RADIO}
                  name={name}
                  value={option.id}
                  checked={selectedOption?.id === option.id}
                  onChange={() => handleOptionChange(option)}
                  className="hidden"
                />
                <label
                  htmlFor={`option-${option.id}`}
                  className={`text-[14px] leading-[18.9px] ${selectedOption?.id === option.id ? 'text-green' : 'text-header'}`}
                >
                  {option.name}
                </label>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default InputSelect;
