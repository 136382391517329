import React, { useState } from 'react';
import { InfoProductCard, MenuOrder } from '..';
import {
  Button,
  ConfirmAction,
  EButtonClass,
  EButtonType,
  FailFetch,
  Loading,
  SuccessNotification,
  WarningCompleteProfile,
} from '../../components';
import { EApiUrls, ICatalogCard } from '../../../api';
import { getCookie, StatusCode, URLS } from '../../../utils';
import { EFailFetch } from '../../components/FailFetch';
import { SESSION_ID_COOKIE } from '../../../utils/consts';
import { mutate } from 'swr';

interface IProductContent {
  data: ICatalogCard | null | undefined;
  isLoading: boolean;
  isError: boolean;
}

const FAIL_FETCH: string = 'Произошла ошибка при заказе приза';
const FAIL_FOUND: string = 'Сертификат не найден';
const FAIL_POINTS: string = 'Недостаточно баллов на счету';
const SUCCESS_MSG: string = 'Приз успешно заказан, ожидайте письмо на электронную почту';

const ProductContent: React.FC<IProductContent> = ({ data, isLoading, isError }) => {
  const [isOpenCountOrder, setIsOpenCountOrder] = useState<boolean>(false);
  const [isOpenWarningCompleteProfile, setIsOpenWarningCompleteProfile] = useState<boolean>(false);
  const [isOpenConfirmOrder, setIsOpenConfirmOrder] = useState<boolean>(false);
  const [isOpenNotification, setIsOpenNotification] = useState<boolean>(false);
  const [notificationMessage, setNotificationMessage] = useState<string>('');

  const [countOrder, setCountOrder] = useState<number>(1);
  const isAuthenticated = getCookie(SESSION_ID_COOKIE);

  const minusCountOrder = () => {
    if (countOrder > 1) {
      setCountOrder(countOrder - 1);
    }
  };

  const plusCountOrder = () => setCountOrder(countOrder + 1);

  const handleClickBtnOrderPrize = () => {
    if (!isAuthenticated) {
      setIsOpenWarningCompleteProfile(true);
    } else {
      setIsOpenConfirmOrder(true);
    }
  };

  const handleOrderProduct = async () => {
    try {
      const response = await fetch(`${EApiUrls.ORDER}`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ id: data?.id, count: countOrder }),
      });

      const dataFetch = await response.json();

      if (response.ok) {
        setNotificationMessage(SUCCESS_MSG);
        setIsOpenNotification(true);
        mutate(EApiUrls.POINTS);
        mutate(EApiUrls.GET_TRANSACTIONS);
        mutate(EApiUrls.CATALOG);
      } else {
        setIsOpenNotification(true);
        if (response.status === StatusCode.BAD_REQUEST) {
          setNotificationMessage(dataFetch.message || FAIL_POINTS);
        } else if (response.status === StatusCode.NOT_FOUND) {
          setNotificationMessage(dataFetch.message || FAIL_FOUND);
        } else {
          setNotificationMessage(dataFetch.message || FAIL_FETCH);
        }
      }
    } catch (error) {
      setNotificationMessage(FAIL_FETCH);
    }
  };

  if (isLoading) return <Loading />;
  if (isError || !data) return <FailFetch text={EFailFetch.ERROR_FETCH} />;

  return (
    <>
      <div className="w-full bg-white pt-[16px] pl-[16px] pr-[16px] pb-[230px] rounded-lg flex-1 flex-col justify-center">
        <InfoProductCard {...data} />
        <div className="fixed left-0 bottom-[88px] w-full rounded-t-[12px]">
          <div className="max-w-[375px] mx-auto px-[16px]">
            <MenuOrder
              isOpenCountOrder={isOpenCountOrder}
              minusCountOrder={minusCountOrder}
              plusCountOrder={plusCountOrder}
              price={data.price}
              countOrder={countOrder}
            />
            <div className="pt-[12px] pb-[24px] bg-white">
              {isOpenCountOrder ? (
                <Button
                  text="Заказать приз"
                  nameClass={EButtonClass.DEF}
                  isLink={false}
                  typeBtn={EButtonType.BUTTON}
                  handle={handleClickBtnOrderPrize}
                />
              ) : (
                <Button
                  text="Указать количество"
                  nameClass={EButtonClass.DEF}
                  isLink={false}
                  typeBtn={EButtonType.BUTTON}
                  handle={() => setIsOpenCountOrder(true)}
                />
              )}
            </div>
          </div>
        </div>
        {isOpenNotification && (
          <SuccessNotification
            title={notificationMessage}
            setIsOpenNotification={setIsOpenNotification}
            button={<Button text="Хорошо" nameClass={EButtonClass.DEF} isLink={true} linkUrl={URLS.CATALOG} />}
            isSuccess={notificationMessage === SUCCESS_MSG}
          />
        )}
      </div>
      {isOpenWarningCompleteProfile && (
        <WarningCompleteProfile setIsOpenWarningCompleteProfile={setIsOpenWarningCompleteProfile} />
      )}
      {isOpenConfirmOrder && (
        <ConfirmAction
          title={`Вы действительно хотите заказать ${data.name}?`}
          btnTextSuccess="Заказать"
          btnTextNegative="Отмена"
          setIsOpenConfirmOrder={setIsOpenConfirmOrder}
          handleSuccessBtn={() => {
            setIsOpenConfirmOrder(false);
            handleOrderProduct();
          }}
          handleNegativeBtn={() => setIsOpenConfirmOrder(false)}
        />
      )}
    </>
  );
};

export default ProductContent;
