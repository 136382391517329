import React from 'react';
import { IIcon } from '..';

const KeyIcon: React.FC<IIcon> = ({ color = '#4B4B4B' }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.918 11.5345C12.9581 13.6999 12.9581 17.2106 10.918 19.376C8.8779 21.5413 5.57021 21.5413 3.53009 19.376C1.48997 17.2106 1.48997 13.6999 3.53009 11.5345C5.57021 9.36913 8.8779 9.36913 10.918 11.5345ZM10.918 11.5345L18.7678 3.203C19.0228 2.93233 19.4363 2.93233 19.6913 3.203L22 5.65347M15.5355 6.63384L18.306 9.57439"
        stroke={color}
        strokeWidth="1.3"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default KeyIcon;
